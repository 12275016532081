import { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CDocUploadDocumentDialog, {
  NeededDoc,
} from "components/CTracker/Steps/AddMissingDocsDialog";

const RequestedDocItem = ({
  document,
  position,
}: {
  document: NeededDoc;
  position: number;
}) => {
  const theme = useTheme();
  const [addDocsDialogOpen, setAddDocsDialogOpen] = useState(false);

  const [file, setFile] = useState<NeededDoc>(document);

  useEffect(() => {
    setFile(document);
  }, [document]);

  const handleOpenModalToUpload = () => {
    setAddDocsDialogOpen(true);
    setFile(document);
  };

  const stepWithOutB2 = ["titleVerification", "insuranceVerification"].includes(
    file.step,
  );
  const borrowerType = file.isBorrower ? "Borrower" : "Co-borrower";
  const documentName = stepWithOutB2
    ? file.docName
    : `${file.docName} ${borrowerType}`;

  return (
    <Box mt={2}>
      {file.submitted && !file?.rejected && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
          gap={2}
        >
          <CheckCircleIcon
            sx={{ mt: 0.5 }}
            fontSize="large"
            htmlColor={theme.palette.success.main}
          />
          <Typography data-cy="documentName">{documentName}</Typography>
          <Typography data-cy="alreadyUploaded">Already uploaded</Typography>
        </Box>
      )}
      {file?.rejected && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
          gap={2}
        >
          <ErrorIcon
            sx={{ mt: 0.5 }}
            fontSize="large"
            htmlColor={theme.palette.error.main}
          />
          <Typography>{documentName}</Typography>
          <Typography>Rejected document</Typography>
        </Box>
      )}
      {file?.submitted === false && (
        <Box className="my-2">
          <Box
            onClick={handleOpenModalToUpload}
            style={{
              border: "1px solid #d8d6de",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
              }}
              gap={2}
            >
              <Typography color="primary" fontSize={"25px"}>
                {position}
              </Typography>
              <Typography fontSize={"20px"}>{documentName}</Typography>
            </Box>
            <button
              type="button"
              style={{
                background: "#6767aa",
                padding: "10px 35px",
                border: "0px",
                color: "white",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
                cursor: "pointer",
              }}
            >
              <Typography fontSize="18px">Upload</Typography>
            </button>
          </Box>
          <CDocUploadDocumentDialog
            open={addDocsDialogOpen}
            handleClose={() => setAddDocsDialogOpen(false)}
            neededDocs={[file]}
            singleDocument={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default RequestedDocItem;
