import { Typography, Box } from "@mui/material";
import { useState } from "react";
import { ProgressBar } from "../progressBar";

import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { PropertyProps } from "../Property";
import { FormDataNumbers } from "..";

export const LoanRequest = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const handleNext = () => {
    if (
      !formData.homeValue ||
      !formData.currentLoanBalance ||
      !formData.requestedLoanAmount
    ) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      onNext();
    }
  };

  const onChange = (value: number, field: keyof FormDataNumbers) => {
    const newFormData = { ...formData };
    newFormData[field] = value;
    setFormData(newFormData);
  };
  const labelStyle = {
    fontWeight: "700",
    fontSize: "14px",
    margin: "0 0 10px",
  };
  return (
    <div>
      <div className="get-started__wrapper get-started__loan-request">
        <Box>
          <Typography
            variant="h2"
            fontFamily={"NunitoSans"}
            fontSize={{
              xs: "32px",
              sm: "38px",
              md: "38px",
              lg: "40px",
              xl: "40px",
            }}
            textAlign={"center"}
            marginTop={"20px"}
            marginBottom={"20px"}
            fontWeight={900}
          >
            We need a few numbers:
          </Typography>
          <ProgressBar progress={50} />
          <Box>
            <Typography
              variant="body2"
              className="get-started__label"
              sx={labelStyle}
              fontFamily={"NunitoSans"}
              fontSize={{
                sm: "18px",
                md: "18px",
                lg: "21px",
                xl: "21px",
              }}
              textAlign={"start"}
              fontWeight={400}
            >
              Estimated Property Value
            </Typography>
            <input
              type="number"
              className="no-spinner"
              value={formData.homeValue}
              onChange={(e) => onChange(parseInt(e.target.value), "homeValue")}
              style={{
                border: error ? "2px solid red" : "2px solid #ccc",
                animation: animation ? "shake 0.3s ease-in-out" : "none",
              }}
            />
            <p
              className="get-started__note"
              style={{
                textAlign: "center",
              }}
            >
              Not sure? You can find this on a real estate site like Zillow.
            </p>
            <Typography
              className="get-started__label"
              sx={labelStyle}
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={{
                sm: "18px",
                md: "18px",
                lg: "21px",
                xl: "21px",
              }}
              textAlign={"start"}
              fontWeight={400}
            >
              Current Mortgage Balance
            </Typography>
            <input
              type="number"
              className="no-spinner"
              value={formData.currentLoanBalance}
              onChange={(e) =>
                onChange(parseInt(e.target.value), "currentLoanBalance")
              }
              style={{
                border: error ? "2px solid red" : "2px solid #20A2D8",
                animation: animation ? "shake 0.3s ease-in-out" : "none",
              }}
            />
            <p
              className="get-started__note"
              style={{
                textAlign: "center",
              }}
            >
              Not sure? You can find this on your latest mortgage statement.
            </p>
          </Box>
          <HBSeparate
            className="step-separate step-separate--separate"
            type="vertical"
          />
          <Typography
            className="get-started__label"
            sx={labelStyle}
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={{
              sm: "18px",
              md: "18px",
              lg: "21px",
              xl: "21px",
            }}
            textAlign={"start"}
            fontWeight={400}
          >
            Do any of these apply to you?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                className="get-started__checkbox"
                id="home-equity-loan"
                name="home-equity-loan"
                checked={true}
              />
              <label
                htmlFor="home-equity-loan"
                style={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  className="get-started__label"
                  sx={{
                    ...labelStyle,
                    marginBottom: 0,
                  }}
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={{
                    sm: "18px",
                    md: "18px",
                    lg: "21px",
                    xl: "21px",
                  }}
                  textAlign={"start"}
                  fontWeight={400}
                >
                  Home Equity Loan/HELOC Balance
                </Typography>
              </label>
            </Box>
            <Box
              sx={{
                width: "100%",
                paddingLeft: "27px",
              }}
            >
              <input
                type="number"
                className="no-spinner"
                value={formData.requestedLoanAmount}
                onChange={(e) =>
                  onChange(parseInt(e.target.value), "requestedLoanAmount")
                }
                style={{
                  border: error ? "2px solid red" : "2px solid #ccc",
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                }}
              />
            </Box>
            <p
              className="get-started__note"
              style={{
                textAlign: "center",
              }}
            >
              Include the remaining loan balance or total line of credit
              available.
            </p>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                className="get-started__checkbox"
                id="home-judgments"
                name="home-judgments"
              />
              <label
                htmlFor="home-judgments"
                style={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  className="get-started__label"
                  sx={{
                    ...labelStyle,
                    marginBottom: 0,
                  }}
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={{
                    sm: "18px",
                    md: "18px",
                    lg: "21px",
                    xl: "21px",
                  }}
                  textAlign={"start"}
                  fontWeight={400}
                >
                  Judgments/Liens
                </Typography>
              </label>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            <CHBButton
              onClick={onPrev}
              classNameContainer="button-prev"
              className="animated-button"
            >
              Prev
            </CHBButton>
            <CHBButton onClick={handleNext}>Next</CHBButton>
          </Box>
        </Box>
      </div>
      <HBSeparate className="step-separate" type="vertical" />
    </div>
  );
};
