import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CDocUploadDocumentDialog, {
  NeededDoc,
} from "components/CTracker/Steps/AddMissingDocsDialog";
import { useState, useEffect } from "react";
import { Fragment } from "react/jsx-runtime";
import Loan from "typedef/Loan";
import { getDocsMessage } from "utils/missingDocsList";
import API from "utils/API";
import useTracker from "components/CTracker/useTrackerContext";
interface UploadEachDocumentProps {
  docsToUpload: NeededDoc[];
  loan?: Loan;
  handleOpenTruv?: (bool: boolean) => void;
}
type TaxInstallmentInfo = {
  installment1StatusName?: string;
  installment2StatusName?: string;
  installmentTotalValue?: number;
};

type TaxAgency = {
  agencyName: string;
  address1: string;
  city: string;
  stateCode: string;
  zipCode: string;
};

type TaxPayment = {
  taxId: string;
  taxPaymentId: number;
  taxInstallmentInfo: TaxInstallmentInfo;
  taxAgency: TaxAgency;
};
export type PayoffObject = {
  payOffId: string;
  amount: number;
  datePayoff?: string;
  borrowerRelated: string;
  name: string;
  borrowerAccount?: string;
  paymentType: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  tradelineId: string;
};

const UploadEachDocument: React.FC<UploadEachDocumentProps> = ({
  docsToUpload,
  loan,
  handleOpenTruv,
}) => {
  const [docToUpload, setDocToUpload] = useState<NeededDoc>();
  const [openResponse, setOpenResponse] = useState(false);
  const { type } = useTracker();
  useEffect(() => {
    const payoffs = loan?.payoff ?? [];
    const taxPayments =
      loan?.borrowerSteps?.titleVerification?.taxPayment ?? [];
    const matchingPayoff = payoffs.some((payoff) =>
      taxPayments.some(
        (payment) => payoff?.name === payment?.taxAgency?.agencyName,
      ),
    );
    if (matchingPayoff) {
      setOpenResponse(true);
    }
  }, [loan]);
  const isBorrower = type === "borrower";
  const documentsLeftToUpload = docsToUpload?.filter((doc: NeededDoc) => {
    const isDocSubmittedOrRejected =
      doc?.submitted === false || doc?.rejected === true;
    const isTruvDocument = doc?.docName === "Truv W2 and paystubs";
    if (isTruvDocument) {
      return isDocSubmittedOrRejected && doc?.isBorrower === isBorrower;
    } else {
      return isDocSubmittedOrRejected;
    }
  });

  const subtmitPayoff = async () => {
    const payOffArray = createPayoffs(
      loan?.borrowerSteps?.titleVerification?.taxPayment as [],
    ).concat((loan?.payoff as []) ?? []);
    try {
      const response = await API.post({
        url: "/invoke",
        data: {
          InvocationType: "RequestResponse",
          FunctionName: "saveToLoanAdmin",
          Service: "nftydoor-admin",
          Payload: {
            body: {
              loanId: loan?.id,
              payOffObject: payOffArray,
              isTradelinePayoff: false,
              isUserTaxPayment: true,
            },
            adminId: undefined,
            loanId: loan?.id,
            pathParameters: {
              task: "save-payoff",
            },
          },
        },
      });
      if (response?.status === 200) {
        setOpenResponse(true);
      } else {
        console.error("Error in response:", response);
      }
    } catch (error) {
      console.error("Error while submitting payoff:", error);
    }
  };
  return (
    <Fragment>
      {documentsLeftToUpload?.length > 0 &&
        documentsLeftToUpload.map((doc: NeededDoc, index: number) => (
          <>
            {doc?.rejected ? (
              <Typography
                key={`${index}${doc?.docName}`}
                fontSize={18}
                variant="h6"
                color={"#ff3d00"}
              >
                The {doc.docName} uploaded was rejected.
                <span
                  style={{ color: "#808080" }}
                  data-cy="reasonRejectedDocument"
                >
                  {" "}
                  Reason: {doc?.rejectReason}
                </span>
              </Typography>
            ) : (
              <Typography
                key={index}
                fontSize={18}
                variant="h6"
                color={"#808080"}
              >
                {getDocsMessage(
                  doc.docName,
                  doc.isBorrower
                    ? loan?.borrowerFirstName
                    : loan?.coborrowerFullName?.split(" ")[0],
                  doc?.yearRequestedByAdmin ?? undefined,
                  doc?.companyName,
                  doc?.lienBalance,
                )}
              </Typography>
            )}
            {doc?.docName === "Payoff Texas 50(a)6" ? (
              <Box sx={{ gap: "20px", display: "flex" }}>
                <div>
                  <Button
                    aria-label={`Upload paid and closed letter`}
                    name="uploadEachDocument-paidAndClosedLetter"
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      whiteSpace: "nowrap",
                      borderRadius: "25px",
                      marginBottom: 1,
                      textTransform: "inherit",
                    }}
                    onClick={() => {
                      setDocToUpload({ ...doc, paidAndClosedLetter: true });
                    }}
                  >
                    Upload paid and closed letter
                  </Button>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontSize: "0.8rem",
                      paddingLeft: "5px",
                    }}
                  >
                    Maximum file size allowed: 100MB
                  </Typography>
                </div>

                <div>
                  <Button
                    aria-label={`Upload a payoff statement`}
                    name="uploadEachDocument-payoffStatement"
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      whiteSpace: "nowrap",
                      borderRadius: "25px",
                      marginBottom: 1,
                      textTransform: "inherit",
                    }}
                    onClick={() => {
                      setDocToUpload(doc);
                    }}
                  >
                    Upload a payoff statement
                  </Button>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontSize: "0.8rem",
                      paddingLeft: "5px",
                    }}
                  >
                    Maximum file size allowed: 100MB
                  </Typography>
                </div>
              </Box>
            ) : doc?.docName === "Proof of Tax Payment" && !openResponse ? (
              <Box sx={{ gap: "20px", display: "flex" }}>
                <div>
                  <Button
                    aria-label={`Yes, I have Proof of Payment`}
                    name="uploadEachDocument-paidAndClosedLetter"
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      whiteSpace: "nowrap",
                      borderRadius: "25px",
                      marginBottom: 1,
                      textTransform: "inherit",
                    }}
                    onClick={() => {
                      setDocToUpload(doc);
                    }}
                  >
                    Yes, I have Proof of Payment
                  </Button>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontSize: "0.8rem",
                      paddingLeft: "5px",
                    }}
                  >
                    Maximum file size allowed: 100MB
                  </Typography>
                </div>

                <div>
                  <Button
                    aria-label={`No, I want to pay at closing`}
                    name="uploadEachDocument-payoffStatement"
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      whiteSpace: "nowrap",
                      borderRadius: "25px",
                      marginBottom: 1,
                      textTransform: "inherit",
                    }}
                    onClick={() => {
                      subtmitPayoff();
                    }}
                  >
                    No, I want to pay at closing
                  </Button>
                </div>
              </Box>
            ) : doc?.docName === "Truv W2 and paystubs" ? (
              <div>
                <Button
                  aria-label={`Upload ${doc?.docName}`}
                  name={`uploadEachDocument-uploadDoc`}
                  variant="contained"
                  sx={{
                    marginTop: 1,
                    whiteSpace: "nowrap",
                    borderRadius: "25px",
                    marginBottom: 1,
                    textTransform: "inherit",
                  }}
                  onClick={() => {
                    handleOpenTruv?.(true);
                  }}
                >
                  Upload {doc?.docName}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  aria-label={`Upload ${doc?.docName}`}
                  name={`uploadEachDocument-uploadDoc`}
                  variant="contained"
                  sx={{
                    marginTop: 1,
                    whiteSpace: "nowrap",
                    borderRadius: "25px",
                    marginBottom: 1,
                    textTransform: "inherit",
                  }}
                  onClick={() => {
                    setDocToUpload(doc);
                  }}
                >
                  Upload {doc?.docName}
                </Button>
                <Typography
                  sx={{
                    marginBottom: 2,
                    fontSize: "0.8rem",
                    paddingLeft: "5px",
                  }}
                >
                  Maximum file size allowed: 100MB
                </Typography>
              </div>
            )}
          </>
        ))}

      {docToUpload && (
        <CDocUploadDocumentDialog
          open={Boolean(docToUpload)}
          handleClose={() => setDocToUpload(undefined)}
          neededDocs={[docToUpload]}
          singleDocument={true}
        />
      )}
      {openResponse && documentsLeftToUpload?.length === 0 && (
        <Typography key={1} fontSize={18} variant="h6" color={"#808080"}>
          {"Great! Your tax will be payed at closing."}
        </Typography>
      )}
    </Fragment>
  );
};
function createPayoffs(taxPayments: TaxPayment[]): PayoffObject[] {
  return taxPayments
    ?.filter((payment) => {
      const isDelinquentInstallment1 =
        payment?.taxInstallmentInfo?.installment1StatusName === "Delinquent";
      const isDelinquentInstallment2 =
        payment?.taxInstallmentInfo?.installment2StatusName === "Delinquent";
      return isDelinquentInstallment1 || isDelinquentInstallment2;
    })
    ?.map((payment) => {
      return {
        borrowerAccount: payment?.taxId,
        payOffId: `payoff-${payment?.taxPaymentId}`,
        amount: payment?.taxInstallmentInfo?.installmentTotalValue as number,
        datePayoff: new Date().toISOString(),
        borrowerRelated: "B1",
        name: payment?.taxAgency?.agencyName as string,
        paymentType: "check" as string,
        address: payment?.taxAgency?.address1,
        city: payment?.taxAgency?.city,
        state: payment?.taxAgency?.stateCode,
        zip: parseInt(payment?.taxAgency?.zipCode as string, 10),
        tradelineId: `tradeline-test-${payment?.taxPaymentId}`,
      };
    });
}
export default UploadEachDocument;
