import { Box } from "@mui/material";
import { HBSeparate } from "components/HBSeparate";
import React from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

export interface CHBLinkProps extends LinkProps {
  children: React.ReactNode;
  separate?: boolean;
}

export const CHBLink = ({ children, separate, ...props }: CHBLinkProps) => {
  const location = useLocation();
  const pathName = location.pathname;
  const isActive = pathName === props.to;
  const { primaryText } = useHomeBridgeColors();
  return (
    <Box display={"flex"} alignItems={"center"} className="chb-link">
      <Link
        className="chb-link__link"
        style={{
          fontWeight: isActive ? "bold" : "normal",
          color: primaryText,
          fontSize: "18px",
          textDecoration: "none",
          textTransform: "uppercase",
          margin: "0 20px",
          borderRadius: "5px",
        }}
        {...props}
      >
        {children}
      </Link>
      {separate && <HBSeparate />}
    </Box>
  );
};
