import { useCallback, useEffect, useMemo, useState } from "react";
import API from "utils/API";

export interface UseVerificationDialogConfig {
  defaultVerificationType: "sms" | "email";
  onConfirmed(): void;
  email?: string;
  phoneNumber?: string;
}

const useVerificationDialog = ({
  defaultVerificationType,
  onConfirmed,
  email,
  phoneNumber,
}: UseVerificationDialogConfig) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [verificationType, setVerificationType] = useState(
    defaultVerificationType,
  );

  useMemo(() => {
    if (defaultVerificationType) {
      setVerificationType(defaultVerificationType);
    }
  }, [defaultVerificationType]);

  const onChangeCode = useCallback((newCode: string) => {
    setCode(newCode);
  }, []);

  const resendCode = useCallback(
    async (typeOverride?: "sms" | "email") => {
      const typeToSend = typeOverride ?? verificationType;
      if (email && typeToSend && phoneNumber) {
        setResendingCode(true);
        const response = await API.post({
          url: "/resend-code",
          data: {
            username: email?.trim(),
            type: typeToSend,
            phoneNumber: phoneNumber?.split(" ")[0],
          },
        });
        if ("error" in response) {
          setError(response.error);
        }
        setResendingCode(false);
      }
    },
    [verificationType, email, phoneNumber],
  );

  const togglerVerificationType = useCallback(() => {
    const newType = verificationType === "sms" ? "email" : "sms";
    setVerificationType(newType);
    resendCode(newType);
  }, [verificationType, resendCode]);

  useEffect(() => {
    (async () => {
      if (code.length === 6) {
        setError("");
        setLoading(true);
        setCode("");
        const response = await API.post({
          url: "/confirm-user",
          data: {
            code,
            type: verificationType,
            username: email?.trim(),
            phoneNumber: phoneNumber?.split(" ")[0],
          },
        });
        setLoading(false);
        if ("error" in response) {
          setError(response.error);
        } else {
          onConfirmed();
        }
      }
    })();
  }, [code, email, onConfirmed, verificationType, phoneNumber]);

  return {
    code,
    error,
    loading,
    resendCode,
    onChangeCode,
    resendingCode,
    verificationType,
    togglerVerificationType,
  };
};

export default useVerificationDialog;
