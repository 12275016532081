import API from "utils/API";
import Loan from "typedef/Loan";
import { useState, useEffect } from "react";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import { useParams } from "react-router-dom";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type Marks = Loan["initialOffer"][];

const useTypeOfCreditLine = (isAdmin?: boolean) => {
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const [loan, setLoan] = useState<Loan>();
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [markSelectIndex, setMarkSelectIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [totalFees, setTotalFees] = useState<number>();
  const { selectedLoanId } = useUser();

  const handleRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  const handleChangeDrawAmount = (value: number) => {
    setLoan((prev) => {
      const previousLoan = prev as Loan;

      return {
        ...previousLoan,
        initialOffer: {
          ...previousLoan?.initialOffer,
          initialDrawAmount: value,
        },
      };
    });
  };

  useEffect(() => {
    const getLoan = async () => {
      setShowErrorMessage(false);
      setLoading(true);

      const loanResponse =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);
      if ("error" in loanResponse) {
        setShowErrorMessage(true);
        setErrorMessage(loanResponse.error);
        setLoading(false);
      } else {
        setLoan(loanResponse.data);
        setLoading(false);
      }
    };

    getLoan();
  }, [refresh, isAdmin, currentAdminLoanId, selectedLoanId]);

  const [fees, setFees] = useState<PrivateLabelFees>();
  const state = loan?.property.address.components.state_abbreviation;
  useEffect(() => {
    setLoading(true);
    API.get<PrivateLabelFees>(
      `/getServiceUnsecured/origination-fees?state=${state}&loanId=${loan?.id}`,
    )
      .then((response) => {
        if (!("error" in response)) {
          setFees(response.data);
          setLoading(false);
        }
      })
      // eslint-disable-next-line
      .catch((_error) => {
        setLoading(false);
      });
  }, [state, loan?.id]);
  const loanFees = loan?.loanFees;

  const originationFee =
    fees?.totalFee && loan?.initialOffer?.amount
      ? fees?.totalFee * loan?.initialOffer?.amount
      : null;
  const taxes =
    loan?.borrowerSteps?.titleVerification.recordingFeesAndOtherTaxes;

  const stateIsVA = state === "VA" ? true : false;
  const originalTotalFee =
    fees?.totalFee !== undefined ? fees?.totalFee : 0.0299;

  const creditReport = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.creditReport ?? 0)
    : 0;
  const complianceDocPrepeNote = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.complianceDocPrepeNote ?? 0)
    : 0;
  const AVMInspection = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.AVMInspection ?? 0)
    : 0;
  const idVerificationFee = !stateIsVA
    ? Number(loanFees?.idVerficationFee ?? 0)
    : 0;
  const eRecordingFee = !stateIsVA ? Number(loanFees?.eRecordingFee ?? 0) : 0;
  const legalVestingFee = !stateIsVA
    ? Number(loanFees?.legalVestingFee ?? 0)
    : 0;
  const taxesValue = !stateIsVA ? Number(taxes ?? 0) : 0;

  const total =
    Number(originationFee ?? 0) +
    creditReport +
    complianceDocPrepeNote +
    AVMInspection +
    idVerificationFee +
    eRecordingFee +
    legalVestingFee +
    taxesValue;

  useEffect(() => {
    setTotalFees(total);
  }, [total]);

  return {
    handleRefresh,
    loan,
    loading,
    errorMessage,
    showErrorMessage,
    markSelectIndex,
    setMarkSelectIndex,
    handleChangeDrawAmount,
    totalFees,
    originationFee,
    originalTotalFee,
  };
};

export default useTypeOfCreditLine;
