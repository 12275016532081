import CLoader from "components/CLoader";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PrivateLabel } from "typedef/PrivateLabel";
import API from "utils/API";
import checkIpOrLocalhost from "utils/checkIpOrLocalhost";

interface PrivateLabelContextType {
  privateLabel: PrivateLabel | undefined;
}

export const PrivateLabelContext = createContext<
  PrivateLabelContextType | undefined
>(undefined);

export const UsePrivateLabelContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [privateLabel, setPrivateLabel] = useState<PrivateLabel>();
  const fetchData = async (hostname: string) => {
    try {
      const response = await API.get<PrivateLabel>({
        url: `/get-unsec/private-label/${hostname}`,
      });
      if ("error" in response) {
        alert(response.error);
      } else {
        const privateLabel = response.data;
        setPrivateLabel(privateLabel);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //const hostname = window.location.hostname;
    //used for test privateLabel
    const hostname = checkIpOrLocalhost(window.location.hostname)
      ? (process.env.REACT_APP_PRIVATE_LABEL_DOMAIN as string)
      : window.location.hostname;
    fetchData(hostname);
  }, []);

  useEffect(() => {
    const includeBorrowerPortalWidget =
      privateLabel?.zendesk?.includeBorrowerPortalWidget;
    const borrowerPortalWidgetUrl =
      privateLabel?.zendesk?.borrowerPortalWidgetUrl;
    const currentHostname = window.location.hostname;
    const isLocalhost = checkIpOrLocalhost(currentHostname);
    const pathName = window.location.pathname;
    if (
      !isLocalhost &&
      includeBorrowerPortalWidget &&
      borrowerPortalWidgetUrl &&
      !pathName.includes("impersonate")
    ) {
      if (!document.getElementById("ze-snippet")) {
        const scriptElement = document.createElement("script");
        scriptElement.id = "ze-snippet";
        scriptElement.src = borrowerPortalWidgetUrl;
        document.head.appendChild(scriptElement);
      }
    }
  }, [
    privateLabel?.zendesk?.borrowerPortalWidgetUrl,
    privateLabel?.zendesk?.includeBorrowerPortalWidget,
  ]);

  const values = useMemo(() => ({ privateLabel }), [privateLabel]);

  if (isLoading) return <CLoader loading={isLoading} />;

  if (privateLabel?.favicon) {
    const existingIcons = document.querySelectorAll(
      "link[rel='icon'], link[rel='shortcut icon']",
    );
    existingIcons.forEach((icon) => icon.parentNode?.removeChild(icon));

    const existingMetaTag = document.querySelector(
      "meta[name='msapplication-TileImage']",
    );
    if (existingMetaTag) {
      existingMetaTag.parentNode?.removeChild(existingMetaTag);
    }

    const favicons = [
      {
        rel: "icon",
        href: privateLabel.favicon,
      },
      {
        rel: "shortcut icon",
        href: privateLabel.favicon,
      },
    ];

    favicons.forEach((favicon) => {
      const link: HTMLLinkElement = document.createElement("link");
      link.rel = favicon.rel;
      link.href = favicon.href;
      document.getElementsByTagName("head")[0].appendChild(link);
    });

    const metaTag = {
      name: "msapplication-TileImage",
      content: privateLabel.favicon,
    };

    const meta: HTMLMetaElement = document.createElement("meta");
    meta.name = metaTag.name;
    meta.content = metaTag.content;
    document.getElementsByTagName("head")[0].appendChild(meta);
  }

  if (privateLabel?.logo) {
    const metaTagsToUpdate = [
      { name: "og:image", content: privateLabel.logo },
      { name: "twitter:image", content: privateLabel.logo },
    ];

    metaTagsToUpdate.forEach((metaTag) => {
      const metaElement =
        document.querySelector(`meta[property='${metaTag.name}']`) ||
        document.querySelector(`meta[name='${metaTag.name}']`);
      if (metaElement) {
        metaElement.setAttribute("content", metaTag.content);
      }
    });
  }

  if (privateLabel?.lenderName) {
    document.title = `${privateLabel.lenderName} - Home Equity Loans`;

    const metaTagsToUpdate = [
      {
        name: "og:title",
        content: `${privateLabel.lenderName} - Home Equity Loans`,
      },
      {
        name: "twitter:title",
        content: `${privateLabel.lenderName} - Home Equity Loans`,
      },
    ];

    metaTagsToUpdate.forEach((metaTag) => {
      const metaElement =
        document.querySelector(`meta[property='${metaTag.name}']`) ||
        document.querySelector(`meta[name='${metaTag.name}']`);
      if (metaElement) {
        metaElement.setAttribute("content", metaTag.content);
      }
    });
  }

  if (privateLabel?.frontUrl) {
    const metaTagsToUpdate = [
      { name: "og:url", content: privateLabel.frontUrl },
      { name: "twitter:url", content: privateLabel.frontUrl },
    ];

    metaTagsToUpdate.forEach((metaTag) => {
      const metaElement =
        document.querySelector(`meta[property='${metaTag.name}']`) ||
        document.querySelector(`meta[name='${metaTag.name}']`);
      if (metaElement) {
        metaElement.setAttribute("content", metaTag.content);
      }
    });
  }

  return (
    <PrivateLabelContext.Provider value={values}>
      {children}
    </PrivateLabelContext.Provider>
  );
};

export function usePrivateLabel() {
  const context = useContext(PrivateLabelContext);
  if (!context) {
    throw new Error("PrivateLabelContextProvider not found");
  }
  return context;
}
