import { Backdrop, Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  classNameModal?: string;
  children: React.ReactNode;
  containerModalStyle?: object;
  modalStyle?: object;
}

export const CustomModal = ({
  open,
  handleClose,
  classNameModal,
  children,
  containerModalStyle,
  modalStyle,
}: CustomModalProps) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 500,
    boxShadow: 24,
    padding: "1rem",
    boxSizing: "border-box",
    p: 4,
    ...modalStyle,
  };

  return (
    <div>
      <Modal
        className={`custom-modal-container ${classNameModal}`}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        sx={{ height: "auto", zIndex: 1035, ...containerModalStyle }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <div>
          <Box sx={style} className={`${classNameModal}--content`}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon htmlColor={"red"} />
            </IconButton>
            {children}
          </Box>
        </div>
      </Modal>
    </div>
  );
};
