import { Box } from "@mui/material";
import HBLogo from "../../../../assets/Icons/homebridge_logo.svg";
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Box>
      <Link to="/">
        <img
          src={HBLogo}
          alt="Homebridge LOGO"
          width={"100%"}
          height={"100%"}
        />
      </Link>
    </Box>
  );
};
