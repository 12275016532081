import { Box, Container, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { Nav } from "./Nav";

export const HBHeader = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const isGetStarted = pathName.includes("/get-started");
  const { primaryBackground, secondaryBackground, secondaryText } =
    useHomeBridgeColors();
  return (
    <Box className="hb-header">
      {!isGetStarted && (
        <Box
          className="hb-header__top"
          sx={{
            backgroundColor: secondaryBackground,
          }}
        >
          <Container className="hb-header__top__container">
            <Box className="hb-header__top">
              <Typography
                variant="body1"
                textAlign="end"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                sx={{
                  padding: "10px 0",
                }}
              >
                <Link
                  to="/payments"
                  style={{
                    color: secondaryText,
                  }}
                >
                  Make a payment
                </Link>
              </Typography>
            </Box>
          </Container>
        </Box>
      )}

      <Box
        className="hb-header__bottom"
        sx={{
          backgroundColor: primaryBackground,
        }}
      >
        <Container className="hb-header__bottom__container">
          <Nav />
        </Container>
      </Box>
    </Box>
  );
};
