import Loan from "typedef/Loan";

enum Paystub {
  "paystub1" = "paystub1",
  "paystub2" = "paystub2",
}
export enum StepName {
  "borrowerSteps" = "borrowerSteps",
  "coborrowerSteps" = "coborrowerSteps",
}
const TOTAL_MONTHS_IN_YEAR = 12;
const SEMI_MONTHLY = 24;
const BI_WEEKLY = 26;
const WEEKLY = 52;

export const calculatePaystubAnnualAmount = ({
  loan,
  paystub,
  stepName,
}: {
  loan: Loan;
  paystub: Paystub;
  stepName: StepName;
}) => {
  const paystubTruv =
    loan?.[stepName]?.incomeVerification?.truvIncome?.[paystub];

  const paystubAmount =
    Number(paystubTruv?.gross_pay) > 0 ? Number(paystubTruv?.gross_pay) : 0;

  let anualAmount = 0;
  if (paystubTruv?.frequency === "Annually") anualAmount = paystubAmount;
  if (paystubTruv?.frequency === "Monthly")
    anualAmount = paystubAmount * TOTAL_MONTHS_IN_YEAR;
  if (paystubTruv?.frequency === "Semi-Monthly")
    anualAmount = paystubAmount * SEMI_MONTHLY;
  if (paystubTruv?.frequency === "Bi-Weekly")
    anualAmount = paystubAmount * BI_WEEKLY;
  if (paystubTruv?.frequency === "Weekly") anualAmount = paystubAmount * WEEKLY;

  return anualAmount;
};

/**@param {{loan:any, selection:string, stepName:'borrowerSteps' | 'coborrowerSteps' }} props */
export const getAnualIncomeByTypeSelection = ({
  loan,
  selection,
  stepName,
}: {
  loan: Loan;
  selection: string;
  stepName: StepName;
}) => {
  const w2TheWorkNumberIncome =
    loan?.[stepName]?.incomeVerification?.theWorkNumber
      ?.projectedAnnualIncome ?? 0;
  const W2AdditionalIncomesAmount =
    loan?.[stepName]?.incomeVerification?.w2AdditionalIncome?.reduce(
      (total, income) => {
        if (income.incomeType === "w2") {
          return total + income.income;
        } else {
          return total;
        }
      },
      0,
    ) ?? 0;
  const filteredSelfEmployedAmount =
    loan?.[
      stepName
    ]?.incomeVerification?.selfEmployed?.selfEmployedData?.reduce(
      (total, income) => {
        if (income.debtIncome === true) {
          return total + income.annual;
        } else {
          return total;
        }
      },
      0,
    ) ?? 0;
  const truvIncomeW2 = loan?.[stepName]?.incomeVerification?.truvIncome?.w2
    ?.medicare_wages
    ? Number(
        loan?.[stepName]?.incomeVerification?.truvIncome?.w2.medicare_wages,
      )
    : loan?.[stepName]?.incomeVerification?.truvIncome?.w2?.gross_pay
      ? Number(loan?.[stepName]?.incomeVerification?.truvIncome?.w2.gross_pay)
      : loan?.[stepName]?.incomeVerification?.truvIncome?.w2?.wages
        ? Number(loan?.[stepName]?.incomeVerification?.truvIncome?.w2?.wages)
        : loan?.[stepName]?.incomeVerification?.w2AdditionalIncome?.[0]?.income
          ? Number(
              loan?.[stepName]?.incomeVerification?.w2AdditionalIncome?.[0]
                ?.income,
            )
          : 0;

  let amount = 0;
  if (selection === "paystub1") {
    const anualAmountPaystub1 = calculatePaystubAnnualAmount({
      loan,
      paystub: Paystub.paystub1,
      stepName,
    });
    const anualAmountPaystub2 = calculatePaystubAnnualAmount({
      loan,
      paystub: Paystub.paystub2,
      stepName,
    });
    const totalBorrowers = 2;
    amount =
      (anualAmountPaystub1 + anualAmountPaystub2) / totalBorrowers +
      Number(filteredSelfEmployedAmount);
  } else if (selection === "paystub2") {
    const anualAmount = calculatePaystubAnnualAmount({
      loan,
      paystub: Paystub.paystub2,
      stepName,
    });
    amount = anualAmount + Number(filteredSelfEmployedAmount);
  } else if (selection === "average-paystub1-paystub2") {
    const anualAmountPaystub1 = calculatePaystubAnnualAmount({
      loan,
      paystub: Paystub.paystub1,
      stepName,
    });
    const anualAmountPaystub2 = calculatePaystubAnnualAmount({
      loan,
      paystub: Paystub.paystub2,
      stepName,
    });
    const totalBorrowers = 2;
    amount =
      (anualAmountPaystub1 + anualAmountPaystub2) / totalBorrowers +
      Number(filteredSelfEmployedAmount);
  } else if (selection === "W2 (TWN)") {
    amount = Number(w2TheWorkNumberIncome) + Number(filteredSelfEmployedAmount);
  } else if (!selection || selection === "W2") {
    amount =
      truvIncomeW2 +
      Number(W2AdditionalIncomesAmount) +
      Number(filteredSelfEmployedAmount);
  } else if (!selection || selection === "W2 (Truv)") {
    const anualAmountPaystub1 = calculatePaystubAnnualAmount({
      loan,
      paystub: Paystub.paystub1,
      stepName,
    });
    const anualAmountPaystub2 = calculatePaystubAnnualAmount({
      loan,
      paystub: Paystub.paystub2,
      stepName,
    });
    const paystubAverage = (anualAmountPaystub1 + anualAmountPaystub2) / 2;
    amount = Math.max(truvIncomeW2, paystubAverage);
  } else if (selection === "plaidCRA") {
    const plaidCRAIncome =
      loan?.[stepName]?.incomeVerification?.plaidCRA?.totalMonthGrossIncome ??
      0;
    amount = Number(plaidCRAIncome) + Number(filteredSelfEmployedAmount);
  } else if (selection === "others") {
    amount = Number(filteredSelfEmployedAmount);
  }

  return amount;
};
