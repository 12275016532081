import { Typography, Box } from "@mui/material";
import { useState } from "react";
import { ProgressBar } from "../progressBar";

import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { PropertyProps } from "../Property";
import { CustomModal } from "../OfferDetailModal";
import { OfferResponse } from "..";
import dollarFormatter from "utils/FormatterDollar";
import { CustomText } from "./CustomText";

interface OfferProps extends PropertyProps {
  offer?: OfferResponse[];
  loading?: boolean;
  error?: string | null;
}

interface CurrentOffer extends OfferResponse {
  index: number;
}

export const Offer = ({
  offer,
  onNext,
  onPrev,
  formData,
  setFormData,
  loading,
  error,
}: OfferProps) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentActive, setCurrentActive] = useState<CurrentOffer>();

  /* const handleNext = () => {
    if (!currentActive) {
      setAnimation(true);
      setErrorMessage(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setErrorMessage(false);
      onNext();
    }
  }; */

  const handleSelect = (index: number) => {
    const currentOffer = offer?.[index];
    if (currentOffer) {
      setFormData({ ...formData, requestedLoanAmount: currentOffer.amount });
      setCurrentActive({ ...currentOffer, index });
      setErrorMessage(false);
    }
  };

  return (
    <Box>
      <div className="get-started__wrapper get-started__offer">
        <Box>
          <Typography
            variant="h2"
            fontFamily={"NunitoSans"}
            fontSize={{
              xs: "32px",
              sm: "38px",
              md: "38px",
              lg: "40px",
              xl: "40px",
            }}
            textAlign={"center"}
            marginTop={"20px"}
            marginBottom={"20px"}
            fontWeight={900}
          >
            <b
              style={{
                color: "#20A2D8",
              }}
            >
              Congrats
            </b>{" "}
            — you’re <br />
            pre-qualified!
          </Typography>

          <ProgressBar progress={100} />

          <Typography
            variant="h4"
            fontFamily={"NunitoSans"}
            fontSize={{
              xs: "18px",
              sm: "20px",
              md: "20px",
              lg: "22px",
              xl: "24px",
            }}
            textAlign={"center"}
            fontWeight={400}
          >
            Choose your offer below:
          </Typography>

          <Box
            sx={{
              display: {
                xs: "grid",
                sm: "flex",
              },
              gridTemplateColumns: {
                xs: "",
              },
              justifyContent: {
                xs: "center",
                sm: "space-between",
              },
              gap: {
                xs: "20px",
                sm: "0px",
              },
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {offer?.map((item, index) => (
              <CHBButton
                key={index}
                onClick={() => handleSelect(index)}
                className={`animated-button ${
                  currentActive?.index === index
                    ? "animated-button--active"
                    : ""
                }`}
                styleContainer={{
                  height: "100%",
                  width: "100%",
                }}
                style={{
                  border: errorMessage ? "2px solid red" : "2px solid #20A2D8",
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                  height: "100%",
                  padding: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    className="offer-number"
                    fontFamily={"NunitoSans"}
                    fontSize={{
                      xs: "16px",
                      sm: "18px",
                      md: "18px",
                      lg: "21px",
                      xl: "21px",
                    }}
                    sx={{
                      color: "#20A2D8",
                      borderRadius: "50%",
                      border: "1px solid #20A2D8",
                      padding: "5px",
                      backgroundColor: "#102939",
                      width: "44px",
                      textAlign: "center",
                      fontSize: "24px",
                    }}
                    textAlign={"center"}
                    fontWeight={700}
                  >
                    {index + 1}
                  </Box>
                  <Typography
                    variant="body2"
                    fontFamily={"NunitoSans"}
                    textAlign={"center"}
                    fontWeight={400}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    Home Equity Line of Credit:
                  </Typography>
                  <Typography
                    variant="h4"
                    fontFamily={"NunitoSans"}
                    fontSize={{
                      xs: "18px",
                      sm: "18px",
                      md: "20px",
                      lg: "20px",
                      xl: "22px",
                    }}
                    textAlign={"center"}
                    fontWeight={700}
                  >
                    {dollarFormatter.format(item.amount)}
                  </Typography>
                </Box>
              </CHBButton>
            ))}
          </Box>

          <CHBButton
            onClick={() => {
              if (!currentActive) {
                setAnimation(true);
                setErrorMessage(true);
                setTimeout(() => {
                  setAnimation(false);
                }, 300);
              } else {
                setErrorMessage(false);
                setOpenModal(true);
              }
            }}
            className="animated-button"
            styleContainer={{
              width: "100%",
              maxWidth: "100%",
            }}
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
          >
            See Offer Details
          </CHBButton>
          <p className="get-started__note">
            Looking for less? You can choose a different HELOC amount once your
            application is finished.
          </p>

          {/* {errorMessage && (
            <p style={{ color: "red" }}>Please select a property type.</p>
          )} */}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Box>
      </div>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <CHBButton
            onClick={onPrev}
            classNameContainer="button-prev"
            className="animated-button"
          >
            Prev
          </CHBButton>
          {/* <CHBButton onClick={handleNext}>Next</CHBButton> */}
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />

      <CustomModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        classNameModal="get-started-offer-modal"
        modalStyle={{ maxWidth: "550px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography
            variant="h4"
            fontFamily={"NunitoSans"}
            fontSize={{
              xs: "22px",
              sm: "24px",
              md: "26px",
              lg: "28px",
            }}
            textAlign={"center"}
            marginTop={"20px"}
            marginBottom={"10px"}
            fontWeight={900}
          >
            {currentActive?.address?.fullAddress ?? ""}
          </Typography>
          <Box
            sx={{
              display: {
                xs: "flex",
              },
              gridTemplateColumns: {
                xs: "",
              },
              justifyContent: {
                xs: "center",
              },
              gap: {
                xs: "20px",
              },
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            {offer?.map((item, index) => (
              <Box key={`${item.amount}--${index}`}>
                <CHBButton
                  key={index}
                  onClick={() => handleSelect(index)}
                  className={`animated-button ${
                    currentActive?.index === index
                      ? "animated-button--active"
                      : ""
                  }`}
                  styleContainer={{
                    height: "100%",
                    width: "100%",
                  }}
                  style={{
                    border: errorMessage
                      ? "2px solid red"
                      : "2px solid #20A2D8",
                    animation: animation ? "shake 0.3s ease-in-out" : "none",
                    height: "100%",
                    padding: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontFamily={"NunitoSans"}
                      fontSize={{
                        xs: "18px",
                        sm: "20px",
                        md: "20px",
                        lg: "22px",
                        xl: "24px",
                      }}
                      textAlign={"center"}
                      fontWeight={700}
                    >
                      {dollarFormatter.format(item.amount)}
                    </Typography>
                  </Box>
                </CHBButton>
              </Box>
            ))}
          </Box>
          <Box textAlign={"start"}>
            <CustomText
              label={"Amount"}
              value={
                dollarFormatter.format(currentActive?.amount as number) ?? ""
              }
            />

            <CustomText
              label={"Draw"}
              value={
                dollarFormatter.format(
                  currentActive?.initialDrawAmount as number,
                ) ?? ""
              }
            />

            <CustomText
              label={"Est. Points and Fees"}
              value={
                dollarFormatter.format(
                  (currentActive?.initialDrawAmount as number) -
                    (currentActive?.netFundingWiredToBank as number),
                ) ?? ""
              }
            />

            <CustomText
              label={"Est. Net Proceeds"}
              value={
                dollarFormatter.format(
                  currentActive?.netFundingWiredToBank as number,
                ) ?? ""
              }
            />

            <CustomText
              label={"Est. Monthly Payment"}
              value={
                dollarFormatter.format(
                  currentActive?.monthlyPayment as number,
                ) ?? ""
              }
            />

            <CustomText
              label={"Monthly Charge"}
              value={
                dollarFormatter.format(
                  currentActive?.monthlyInterestCharge as number,
                ) ?? ""
              }
            />
            <CustomText
              label={"Occupancy"}
              value={currentActive?.occupancyType ?? ""}
            />

            {/*
            <CustomText
              label={"Purpose"}
              value={currentActive?.loanPurpose ?? ""}
            />
             <CustomText
              label={"Interest Rate"}
              value={`${
                currentActive?.interestRate
                  ? ((currentActive?.interestRate as number) * 100).toFixed(2) +
                    "%"
                  : ""
              }`}
            />
            <CustomText
              label={"APR"}
              value={`${
                currentActive?.APR
                  ? ((currentActive?.APR as number) * 100).toFixed(2) + "%"
                  : ""
              }`}
            />

            <CustomText
              label={"CLTV"}
              value={
                currentActive?.cltv
                  ? ((currentActive?.cltv as number) * 100).toFixed(2) + "%"
                  : ""
              }
            />

            <CustomText
              label={"DTI Ratio"}
              value={
                currentActive?.debtToIncomeRatio
                  ? (
                      (currentActive?.debtToIncomeRatio as number) * 100
                    ).toFixed(2) + "%"
                  : ""
              }
            /> */}
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
};
