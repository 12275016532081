import { ButtonProps } from "@mui/material";
import "./styles.scss";
export interface CHBButtonProps extends ButtonProps {
  children: React.ReactNode;
  styles?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  classNameContainer?: string;
  loading?: boolean;
}

export const CHBButton = ({ children, styles, ...props }: CHBButtonProps) => {
  return (
    <div
      className={`animated-arrow-button-container ${
        props?.classNameContainer ?? ""
      }`}
      style={props?.styleContainer}
    >
      <button
        className={`${
          props.loading
            ? "animated-button animated-button--loading"
            : "animated-arrow-button"
        }`}
        {...(!props.loading && { ...props })}
      >
        {props.loading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <span></span>
            <p className="text-black">{children}</p>
          </>
        )}
      </button>
    </div>
  );
};
