import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import useUser from "context/UserCustomer/useUserCustomerContext";

type Inputs = {
  email: string;
  password: string;
};

const useLoginForm = (isAdmin?: boolean) => {
  const { login } = useUser();
  const location = useLocation();
  const search = location.search;
  const urlSearchParams = new URLSearchParams(search);
  const adminEmail = urlSearchParams.get("adminEmail");

  const dataAdminEmail = adminEmail
    ? decodeURIComponent(adminEmail?.toLowerCase().trim())
    : undefined;
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email");

  const [loginError, setLoginError] = useState<string>();
  const form = useForm<Inputs>({
    defaultValues: {
      email: emailParam ?? "",
    },
  });

  const emailWatch = form.watch("email");
  const passwordWatch = form.watch("password");

  const onSubmit = form.handleSubmit(async (data) => {
    const result = await login(
      data?.email?.toLowerCase().trim(),
      data.password,
    );
    if (result) {
      setLoginError(result);
    }
  });

  useEffect(() => {
    setLoginError(undefined);
  }, [emailWatch, passwordWatch]);

  return {
    onSubmit,
    loginError,
    dataAdminEmail: dataAdminEmail,
    isAdmin,
    ...form,
  };
};

export default useLoginForm;
