import { Typography, Box } from "@mui/material";

import HELLAFAST from "../../../assets/Icons/hella_fast.svg";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

export const HellaFast = () => {
  const { tertiaryText, quaternaryText } = useHomeBridgeColors();
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      marginTop={"30px"}
      flexDirection={"column"}
    >
      <Box>
        <img
          src={HELLAFAST}
          alt="Homebridge LOGO"
          width={"100%"}
          height={"100%"}
        />
      </Box>
      <Typography
        variant="h2"
        fontSize={{
          xs: "66px",
          sm: "68px",
          md: "88px",
          lg: "98px",
          xl: "118px",
        }}
        textAlign={"center"}
        fontFamily={"Titillium Bold Italic"}
        color={quaternaryText}
      >
        HELLA FAST
      </Typography>
      <Typography
        variant="h3"
        fontSize={{
          xs: "30px",
          md: "48px",
          lg: "36px",
          xl: "56px",
        }}
        fontFamily={"Titillium Bold Italic"}
        color={tertiaryText}
        lineHeight={"87%"}
        letterSpacing={{
          xs: "21px",
          lg: "32px",
        }}
      >
        HELOC
      </Typography>
    </Box>
  );
};
