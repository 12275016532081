import React, { useState } from "react";
import { HellaFast } from "screens/HBHome/HellaFast";
import { Box, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { FormData } from "..";
import AddressType from "typedef/Address";
import useAddressAutoComplete from "components/CAddressAutocomplete/useAddressAutocomplete";

interface AddressProps {
  onNext: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const Address = ({ onNext, formData, setFormData }: AddressProps) => {
  const [error, setError] = useState(false);
  const addressType: AddressType | undefined = undefined;
  const [currentAddress, setCurrentAddress] = useState<AddressType>();
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [animation, setAnimation] = useState(false);
  const {
    text,
    setText,
    handleOnInputChange,
    handleOnChange,
    options,
    loading,
  } = useAddressAutoComplete({
    address: addressType,
    setAddress: setCurrentAddress as React.Dispatch<AddressType | null>,
  });

  const handleNext = () => {
    if (!currentAddress) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      setFormData({
        ...formData,
        city: currentAddress.city,
        state: currentAddress.state,
        street_line: currentAddress.street_line,
        secondary: currentAddress.secondary ?? "",
        zipcode: currentAddress.zipcode,
      });
      onNext();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOnInputChange(e, e.target.value, "input");
    setOpenSuggestions(true);
  };

  return (
    <div>
      <div>
        <HellaFast />
      </div>

      <div
        className="get-started__wrapper get-started__address"
        style={{
          fontFamily: "NunitoSans",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          fontFamily={"NunitoSans"}
          fontSize={{
            xs: "32px",
            sm: "38px",
            md: "38px",
            lg: "40px",
            xl: "40px",
          }}
          textAlign={"center"}
          marginTop={"20px"}
          marginBottom={"20px"}
          fontWeight={900}
        >
          Get started in 2 minutes!
        </Typography>
        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={{
            sm: "18px",
            md: "18px",
            lg: "21px",
            xl: "21px",
          }}
          marginBottom={"10px"}
          fontWeight={400}
        >
          What’s the address of the property?
        </Typography>

        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <input
            type="text"
            value={text}
            onChange={handleChange}
            style={{
              border: error ? "2px solid red" : "2px solid #ccc",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
            }}
          />
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {loading && (
              <p
                className="spinner"
                style={{
                  padding: "10px",
                  margin: 0,
                }}
              ></p>
            )}
          </Box>

          {options.length > 0 && openSuggestions && (
            <Box
              sx={{
                marginTop: "10px",
                backgroundColor: "#102939",
                border: "1px solid #20A2D8",
                borderTop: "none",
              }}
            >
              {options.map((option, index) => (
                <div
                  className="get-started__address__option"
                  style={{
                    borderBottom: "1px solid #20A2D8",
                    padding: "10px",
                  }}
                  key={index}
                  onClick={(e) => {
                    handleOnChange(e, option, "selectOption", undefined);
                    setOpenSuggestions(false);
                    setText(addressToString(option));
                  }}
                >
                  {addressToString(option)}
                </div>
              ))}
            </Box>
          )}
        </Box>

        <p className="get-started__note">
          This is the property you’ll use to secure your HELOC.
        </p>
        <CHBButton onClick={handleNext}>Next</CHBButton>
      </div>
      <HBSeparate className="step-separate" type="vertical" />
    </div>
  );
};

function addressToString(incomingAddress: {
  city: string;
  state: string;
  street_line: string;
  secondary?: string;
  zipcode: string;
  entries?: number;
}): string {
  const address = { ...incomingAddress };
  if (!address) return "";
  let whiteSpace = "";
  if (address.secondary) {
    if ((address.entries ?? 0) > 1) {
      address.secondary += ` (${address.entries})`;
    }
    whiteSpace = " ";
  }
  return `${address.street_line}${whiteSpace}${address.secondary}, ${address.city}, ${address.state}, ${address.zipcode}`;
}
