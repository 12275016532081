export const useHomeBridgeColors = () => {
  return {
    primaryText: "#FFFFFF",
    secondaryText: "#102939",
    tertiaryText: "#20A2D8",
    quaternaryText: "#D3E1EA",
    primaryBackground: "#102939",
    secondaryBackground: "#D9D9D9",
  };
};
