import API from "utils/API";
import { usePlaidLink } from "react-plaid-link";
import { useCallback, useEffect, useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";
import { useIncomeVerification } from "./useIncomeVerification";

const usePlaid = () => {
  const { loan, type, isModalOpen } = useTracker();
  const { isBorrower } = useIncomeVerification(loan, type, isModalOpen);
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [loadingPlaid, setLoadingPlaid] = useState<boolean>(false);
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  /** usePlaidLink execute the modal of plaid and you get the plaidStatus */
  const { open, ready, exit } = usePlaidLink({
    token: linkToken,
    onEvent: async (eventName, eventMetadata) => {
      if (eventName === "HANDOFF" && updateMode) {
        await API.post({
          url: `/webhook-plaid/${loan?.id}/update/${
            isBorrower ? "borrower" : "coborrower"
          }?plaidUserId=${loan?.id}--${
            isBorrower ? loan?.borrowerId : loan?.coborrowerId
          }`,
          data: {
            fromTracker: true,
            loanId: loan?.id,
            linkToken,
            link_session_id: eventMetadata?.link_session_id,
            status: "success",
            webhook_code: "SESSION_FINISHED",
            webhook_type: "LINK",
          },
        });
      }
      await API.post({
        url: `/save-to-loan/plaid-event`,
        data: {
          loanId: loan?.id,
          event: {
            eventName,
            eventMetadata,
          },
        },
      });
    },
    onSuccess: () => null,
  });

  const loadEmployment = useCallback(
    async (updateMode?: boolean, itemId?: string) => {
      setLoadingPlaid(true);
      setUpdateMode(false);

      const response = await API.get<{ link_token: string }>(
        `/get-plaid/income/link-token?loanId=${loan?.id}&updateMode=${updateMode}&itemId=${itemId}`,
      );
      if ("error" in response) {
        console.error(response.error);
      } else {
        setLinkToken(response.data.link_token);
        if (updateMode) {
          setUpdateMode(updateMode);
        }
      }
    },
    [loan?.id],
  );

  useEffect(() => {
    if (linkToken) {
      open();
      setLoadingPlaid(false);
    }
  }, [ready, exit, open, linkToken]);

  return {
    loadEmployment,
    loadingPlaid,
  };
};

export default usePlaid;
