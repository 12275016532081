import { Fragment } from "react";
import CHeader from "components/CHeader";
import CFooter from "components/CFooter";
import { Outlet, useLocation } from "react-router-dom";
import { HBHeader } from "components/HBHeader";
import { HBFooter } from "components/HBFooter";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import "./styles.scss";
import { useIsHomebridge } from "utils/useIsHomebridge";

const Layout = () => {
  const { isHomeBridge } = useIsHomebridge();
  const location = useLocation();
  const pathName = location.pathname;
  const isGetStarted = pathName.includes("/get-started");
  const { primaryBackground, primaryText } = useHomeBridgeColors();
  return (
    <Fragment>
      {isHomeBridge ? (
        <div
          className="homebridge-layout"
          style={{
            display: "grid",
            minHeight: "100dvh",
            gridTemplateRows: "auto 1fr auto",
            fontFamily: "NunitoSans",
          }}
        >
          <HBHeader />
          <div
            style={{
              backgroundColor: primaryBackground,
              color: primaryText,
            }}
          >
            <Outlet />
          </div>
          {!isGetStarted && <HBFooter />}
        </div>
      ) : (
        <>
          <CHeader />
          <Outlet />
          <CFooter />
        </>
      )}
    </Fragment>
  );
};

export default Layout;
