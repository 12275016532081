import API, { Data } from "utils/API";
import { useState, useEffect, useMemo } from "react";
import Address from "typedef/Address";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useParseToSubmit from "./useParseToSubmit";
import { passwordMsgHelper, regexPassword } from "CONST";
import parseMoney from "utils/parseMoney";
import useInvite from "context/Invite/useInviteContext";
import { Occupancy } from "typedef/Loan";
import statesList from "utils/statesList";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import PricingEngine from "typedef/PricingEngine";
import moment from "moment";
import { MasterLoanOfficer } from "screens/BorrowerInviteRegisterForm";
import { CheckPhone } from "screens/BorrowerInviteRegisterForm/useRegisterForm";
import qs from "qs";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { Checkbox } from "typedef/PrivateLabel";

export type RegisterFormDialogs =
  | "terms"
  | "privacy"
  | "verify"
  | "error"
  | "communications"
  | "MAX_OFFER_ERROR"
  | "EMAIL_IN_USE_ON_PL"
  | "EXISTANT_USER_NOT_LOGGED";

type Ocuppancy = "Primary Residence" | "Second Home" | "Investment Property";
type PhoneValidationState = {
  isValid: boolean;
  failed: string | null;
  phoneTypeBorrower: "MOBILE" | "VOIP" | "LANDLINE" | "OTHER";
  phoneTypeCoborrower: "MOBILE" | "VOIP" | "LANDLINE" | "OTHER";
};
export type RegisterFormInputs = {
  password: string;
  annualIncome?: string;
  creditScore: string;
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  phoneNumber: string;
  maritalStatus: string;
  databaseIdPersona: string;
  reportIdPersona: string;
  inviteCode?: string;
  sourceId?: "fortuna" | "ladu";
  occupancy: Ocuppancy;
  borrowerAddress?: Address;
  propertyAddress: Address;
  currentLoanBalance: string;
  requestedNewLoan: string;
  useProceeds: string;
  employmentType?: string;
  homeValue: string;
  agreeNotifications: boolean;
  employerNameBorrower?: string;
  startDateBorrower?: string;
  manual_street_name?: string;
  manual_street_number?: string;
  manual_street_suffix?: string;
  manual_city?: string;
  manual_zipcode?: string;
  manual_state?: string;
  manual_apt?: string;
  manualBorrowerAddress?: boolean;
  manual_borrower_street_name?: string;
  manual_borrower_street_number?: string;
  manual_borrower_street_suffix?: string;
  manual_borrower_city?: string;
  manual_borrower_zipcode?: string;
  manual_borrower_state?: string;
  manual_borrower_apt?: string;
  annualIncomeToString: string;
  annualIncomeCoborrowerToString?: string;
  salaryIncome?: string;
  selfEmploymentIncome?: string;
  socialIncome?: string;
  otherIncome?: string;
  loanOfficerId?: string;
  legalFirstNameCoborrower?: string;
  legalLastNameCoborrower?: string;
  legalMiddleNameCoborrower?: string;
  legalSuffixCoborrower?: string;
  maritalStatusCoborrower?: string;
  phoneCoborrower?: string;
  confirmEmailAddressCoborrower?: string;
  salaryIncomeCoborrower?: string;
  selfEmploymentIncomeCoborrower?: string;
  socialIncomeCoborrower?: string;
  otherIncomeCoborrower?: string;
  startDateCoborrower?: string;
  employerNameCoborrower?: string;
  totalAnnualIncomeCoborrower?: string;
  inputsGroup?: {
    salaryIncome: string;
    selfEmploymentIncome: string;
    socialIncome: string;
    otherIncome: string;
  };
  inputsGroupCoborrower?: {
    salaryIncomeCoborrower: string;
    selfEmploymentIncomeCoborrower: string;
    socialIncomeCoborrower: string;
    otherIncomeCoborrower: string;
  };
  addCoborrower?: string;
  isLoggedIn: boolean;
} & {
  [key: `signUpCheckbox_${string}`]: boolean;
};
const parseToNumbers = (value: string) => {
  const numericValue = value.replace(/[$,.]/g, "");
  return parseFloat(numericValue);
};
export const handleOcuppancy = (value: string) => {
  switch (value) {
    case "Primary Residence":
      return "HELOC_PRIMARY_RESIDENCE";
    case "Second Home":
      return "HELOC_SECOND_HOME";
    case "Investment Property":
      return "HELOC_INVESTMENT_PROPERTY";
    default:
      return "HELOC_PRIMARY_RESIDENCE";
  }
};
function useYupValidationSchema(
  pricingEngine: PricingEngine,
  checkboxes?: Checkbox[] | null,
) {
  return useMemo(() => {
    const generateCheckboxValidationSchema = (checkboxes: Checkbox[]) => {
      const validationSchema: Record<string, yup.BooleanSchema> = {};

      (checkboxes ?? [])
        .filter((checkbox) => checkbox?.isRequired)
        .forEach((checkbox) => {
          validationSchema[`signUpCheckbox_${checkbox?.key}`] = yup
            .boolean()
            .oneOf([true], `Please accept the checkbox`)
            .required(`Accept required checkbox`);
        });

      return validationSchema;
    };

    const checkboxValidations = checkboxes
      ? generateCheckboxValidationSchema(checkboxes)
      : {};
    return yup.object().shape({
      propertyAddress: yup
        .mixed()
        .required("Sub property address is required."),
      manual_street_name: yup
        .string()
        .when(["propertyAddress"], (propertyAddress, schema) => {
          if (propertyAddress?.street_line === "My address is not listed") {
            return schema
              .required("Street name is required")
              .max(
                45,
                "The street name must be less than 45 letters or numbers.",
              );
          }
        }),
      manual_street_number: yup
        .string()
        .when(["propertyAddress"], (propertyAddress, schema) => {
          if (propertyAddress?.street_line === "My address is not listed") {
            return schema
              .required("Street number is required")
              .max(6, "The street number must be less than 6 digits.")
              .matches(
                /^[a-zA-Z0-9\\-]+$/,
                "The street number must be a number and cannot contain special characters or spaces",
              )
              .trim();
          }
        }),
      manual_street_suffix: yup
        .string()
        .when(["propertyAddress"], (propertyAddress, schema) => {
          if (propertyAddress?.street_line === "My address is not listed") {
            return schema
              .required("Street suffix is required")
              .max(6, "The street suffix must be less than 6 letters.")
              .matches(
                /^[a-zA-Z]+$/,
                "The street suffix must only contain letters and cannot contain numbers or special characters",
              );
          }
        }),
      manual_city: yup
        .string()
        .when(["propertyAddress"], (propertyAddress, schema) => {
          if (propertyAddress?.street_line === "My address is not listed") {
            return schema
              .required("City is required")
              .max(45, "The city must be less than 45 letters.")
              .matches(
                /^[A-Za-z\s]+$/,
                "City can only contain letters and spaces",
              );
          }
        }),
      manual_zipcode: yup
        .string()
        .when(["propertyAddress"], (propertyAddress, schema) => {
          if (propertyAddress?.street_line === "My address is not listed") {
            return schema
              .required("Zipcode is required")
              .matches(/^\d+$/, "Zipcode can only contain numbers")
              .test(
                "lengthZ",
                "Zipcode must be 5 digits long",
                (value: string) => {
                  if (!value) return true;
                  else return value?.length === 5;
                },
              );
          }
        }),
      manual_state: yup
        .string()
        .when(["propertyAddress"], (propertyAddress, schema) => {
          if (propertyAddress?.street_line === "My address is not listed") {
            return schema
              .required("State is required")
              .test("length", "State must be abbreviated", (value: string) => {
                if (!value) return true;
                else return value?.length === 2;
              })
              .test("list", "State is not valid", (value: string) => {
                if (!value) return true;
                else return statesList.includes(value);
              });
          }
        }),
      manual_apt: yup.string().optional(),
      borrowerAddress: yup.mixed().when(
        ["occupancy", "propertyAddress"],
        //@ts-ignore
        (occupancy: Ocuppancy, propertyAddress: Address, schema) => {
          if (occupancy !== "Primary Residence")
            return schema
              .required("Primary home address is required")
              .test(
                "sakeAddress",
                "Sub property address and Primary home address cannot be the same.",
                (borrowerValue: Address) => {
                  return borrowerValue?.street_line ===
                    "My address is not listed"
                    ? true
                    : JSON.stringify(propertyAddress) !==
                        JSON.stringify(borrowerValue);
                },
              );
        },
      ),
      manual_borrower_street_number: yup.string().when(
        ["occupancy", "borrowerAddress"],
        //@ts-ignore
        (occupancy: Occupancy, borrowerAddress: Address, schema) => {
          if (
            borrowerAddress?.street_line === "My address is not listed" &&
            occupancy !== "Primary Residence"
          ) {
            return schema
              .required("Street number is required")
              .max(8, "The street number must be less than 6 digits")
              .matches(
                /^[a-zA-Z0-9\\-]+$/,
                "The street number must be a number and cannot contain special characters or spaces",
              )
              .trim();
          }
        },
      ),
      manual_borrower_street_name: yup.string().when(
        ["occupancy", "borrowerAddress"],
        //@ts-ignore
        (occupancy: Occupancy, borrowerAddress: Address, schema) => {
          if (
            borrowerAddress?.street_line === "My address is not listed" &&
            occupancy !== "Primary Residence"
          ) {
            return schema
              .required("Street name is required")
              .max(
                45,
                "The street name must be less than 45 letters or numbers.",
              );
          }
        },
      ),
      manual_borrower_street_suffix: yup.string().when(
        ["occupancy", "borrowerAddress"],
        //@ts-ignore
        (occupancy: Occupancy, borrowerAddress: Address, schema) => {
          if (
            borrowerAddress?.street_line === "My address is not listed" &&
            occupancy !== "Primary Residence"
          ) {
            return schema
              .required("Street suffix is required")
              .max(6, "The street suffix must be less than 6 letters.")
              .matches(
                /^[a-zA-Z]+$/,
                "The street suffix must only contain letters and cannot contain numbers or special characters",
              );
          }
        },
      ),
      manual_borrower_city: yup.string().when(
        ["occupancy", "borrowerAddress"],
        //@ts-ignore
        (occupancy: Occupancy, borrowerAddress: Address, schema) => {
          if (
            borrowerAddress?.street_line === "My address is not listed" &&
            occupancy !== "Primary Residence"
          ) {
            return schema
              .required("City is required")
              .max(45, "The city must be less than 45 letters.")
              .matches(
                /^[A-Za-z\s]+$/,
                "City can only contain letters and spaces",
              );
          }
        },
      ),
      manual_borrower_zipcode: yup.string().when(
        ["occupancy", "borrowerAddress"],
        //@ts-ignore
        (occupancy: Occupancy, borrowerAddress: Address, schema) => {
          if (
            borrowerAddress?.street_line === "My address is not listed" &&
            occupancy !== "Primary Residence"
          ) {
            return schema
              .required("Zipcode is required")
              .test(
                "lengthZ",
                "Zipcode must be 5 digits long",
                (value: string) => {
                  if (!value) return true;
                  else return value?.length === 5;
                },
              );
          }
        },
      ),
      manual_borrower_state: yup.string().when(
        ["occupancy", "borrowerAddress"],
        //@ts-ignore
        (occupancy: Occupancy, borrowerAddress: Address, schema) => {
          if (
            borrowerAddress?.street_line === "My address is not listed" &&
            occupancy !== "Primary Residence"
          ) {
            return schema
              .required("State is required")
              .test("length", "State must be abbreviated", (value: string) => {
                if (!value) return true;
                else return value?.length === 2;
              })
              .test("list", "State is not valid", (value: string) => {
                if (!value) return true;
                else return statesList.includes(value);
              });
          }
        },
      ),
      manual_borrower_apt: yup.string().optional(),
      creditScore: yup.string().required("Credit score is required."),
      useProceeds: yup.string().required("Use of proceeds is required."),
      occupancy: yup.string().required("Occupancy type is required."),
      annualIncome: yup
        .string()
        .test("max", "The value cannot exceed $9,999,999.99.", function () {
          const {
            salaryIncome,
            selfEmploymentIncome,
            socialIncome,
            otherIncome,
          } = this.parent;
          const totalIncome = [
            parseMoney(salaryIncome),
            parseMoney(selfEmploymentIncome),
            parseMoney(socialIncome),
            parseMoney(otherIncome),
          ].reduce((sum, value) => sum + (value || 0), 0);
          return totalIncome <= 9999999.99;
        })
        .test("min", "The value cannot be less than $10,000.", function () {
          const {
            salaryIncome,
            selfEmploymentIncome,
            socialIncome,
            otherIncome,
          } = this.parent;
          const totalIncome = [
            parseMoney(salaryIncome),
            parseMoney(selfEmploymentIncome),
            parseMoney(socialIncome),
            parseMoney(otherIncome),
          ].reduce((sum, value) => sum + (value || 0), 0);
          return totalIncome >= 10000;
        })
        .test(
          "min",
          "Total Annual Income must be greater than $0.",
          function () {
            const {
              salaryIncome,
              selfEmploymentIncome,
              socialIncome,
              otherIncome,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncome),
              parseMoney(selfEmploymentIncome),
              parseMoney(socialIncome),
              parseMoney(otherIncome),
            ].reduce((sum, value) => sum + (value || 0), 0);
            return totalIncome > 0;
          },
        ),
      employmentType: yup.string().optional(),
      employerNameBorrower: yup.string().when("salaryIncome", {
        is: (value: string) => value !== undefined && value !== "",
        then: yup
          .string()
          .max(200, "Employer Name length must be less than or equal to 200")
          .required("Employer name is required."),
        otherwise: yup.string().optional(),
      }),
      startDateBorrower: yup.string().when("salaryIncome", {
        is: (value: string) => value !== undefined && value !== "",
        then: yup
          .string()
          .required("Start date is required.")
          .test("min date", "Date must be after 1908-02-05", (value) => {
            return new Date(value as string) >= new Date("1908-02-05");
          })
          .test(
            "max date",
            `Date must be before ${moment().format("YYYY-MM-DD")}`,
            (value) => {
              return new Date(value as string) <= new Date();
            },
          ),
        otherwise: yup.string().optional(),
      }),
      startDateCoborrower: yup
        .string()
        .when(["addCoborrower", "salaryIncomeCoborrower"], {
          is: (addCoborrower: string, salaryIncomeCoborrower: string) =>
            addCoborrower === "Yes" &&
            salaryIncomeCoborrower !== undefined &&
            salaryIncomeCoborrower !== "",
          then: yup
            .string()
            .required("Start date is required.")
            .test("min date", "Date must be after 1908-02-05", (value) => {
              return new Date(value as string) >= new Date("1908-02-05");
            })
            .test(
              "max date",
              `Date must be before ${moment().format("YYYY-MM-DD")}`,
              (value) => {
                return new Date(value as string) <= new Date();
              },
            ),
          otherwise: yup.string().optional(),
        }),
      email: yup
        .string()
        .email("Email has invalid format.")
        .trim()
        .lowercase()
        .test("unique-extension", "Email has invalid format.", (value) => {
          const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
          const uniqueExtensions = Array.from(new Set(extensions));
          return extensions.length === uniqueExtensions.length;
        })
        .required("Email is required."),
      maritalStatus: yup.string().required("Marital status is required."),
      phoneNumber: yup
        .string()
        .trim()
        .required("Phone number is required.")
        .test(
          "minLenght",
          "The phone number must be 11 characters long.",
          (value) => {
            if (!value) return true;
            if (process.env.REACT_APP_ENV === "prod") {
              return value.replaceAll(/ /g, "").length === 12;
            }
            return true;
          },
        ),
      isLoggedIn: yup.boolean().default(false),
      password: yup.string().when("isLoggedIn", {
        is: (value: boolean) => value === true,
        then: yup.string().notRequired(),
        otherwise: yup
          .string()
          .required("Password is required.")
          .max(100, "The password must be less than 100 characters.")
          .min(9, "The password must be at least 9 characters long.")
          .matches(regexPassword, passwordMsgHelper),
      }),
      salaryIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Salary income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      selfEmploymentIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Self employment income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      socialIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Social income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      otherIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Other income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      inputsGroup: yup
        .object()
        .test(
          "at-least-one",
          "Income information is required to apply and create a valid offer afterwards",
          function () {
            const {
              salaryIncome,
              selfEmploymentIncome,
              socialIncome,
              otherIncome,
            } = this.parent;
            return (
              salaryIncome ||
              selfEmploymentIncome ||
              socialIncome ||
              otherIncome
            );
          },
        ),
      inputsGroupCoborrower: yup.object().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .object()
          .test(
            "at-least-one",
            "Income information is required to apply and create a valid offer afterwards",
            function () {
              const {
                salaryIncomeCoborrower,
                selfEmploymentIncomeCoborrower,
                socialIncomeCoborrower,
                otherIncomeCoborrower,
              } = this.parent;
              return (
                salaryIncomeCoborrower ||
                selfEmploymentIncomeCoborrower ||
                socialIncomeCoborrower ||
                otherIncomeCoborrower
              );
            },
          ),
      }),
      addCoborrower: yup.string().optional(),
      legalFirstNameCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup.string().required("Legal first name is required."),
        otherwise: yup.string().optional(),
      }),
      legalLastNameCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup.string().required("Legal last name is required."),
        otherwise: yup.string().optional(),
      }),
      legalMiddleNameCoborrower: yup.string().optional(),
      legalSuffixCoborrower: yup.string().optional(),
      maritalStatusCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup.string().required("Marital status is required."),
        otherwise: yup.string().optional(),
      }),
      phoneCoborrower: yup
        .string()
        .trim()
        .when("addCoborrower", {
          is: (addCoborrower: string) => addCoborrower === "Yes",
          then: yup
            .string()
            .required("Phone number is required.")
            .test(
              "minLenght",
              "The phone number must be 11 characters long.",
              (value) => {
                if (!value) return true;
                if (process.env.REACT_APP_ENV === "prod") {
                  return value.replaceAll(/ /g, "").length === 12;
                }
                return true;
              },
            )
            .notOneOf(
              [yup.ref("phoneBorrower")],
              "Phone number must be different from the borrower's phone number.",
            ),
          otherwise: yup.string().optional(),
        }),
      confirmEmailAddressCoborrower: yup.string().when("addCoborrower", {
        is: (value: string) => value === "Yes",
        then: yup
          .string()
          .email("Email has invalid format.")
          .trim()
          .lowercase()
          .test("unique-extension", "Email has invalid format.", (value) => {
            const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
            const uniqueExtensions = Array.from(new Set(extensions));
            return extensions.length === uniqueExtensions.length;
          })
          .notOneOf(
            [yup.ref("email")],
            "Co-borrower's email must be different from borrower's email.",
          )
          .required("Email is required."),
        otherwise: yup.string().optional(),
      }),
      employerNameCoborrower: yup
        .string()
        .when(["addCoborrower", "salaryIncomeCoborrower"], {
          is: (addCoborrower: string, salaryIncomeCoborrower: string) =>
            addCoborrower === "Yes" &&
            salaryIncomeCoborrower !== undefined &&
            salaryIncomeCoborrower !== "",
          then: yup.string().required("Employer name is required."),
          otherwise: yup.string().optional(),
        }),
      salaryIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Salary income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      selfEmploymentIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Self employment income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      socialIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Social income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      otherIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Other income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      totalAnnualIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test("max", "The value cannot exceed $9,999,999.99.", function () {
            const {
              salaryIncomeCoborrower,
              selfEmploymentIncomeCoborrower,
              socialIncomeCoborrower,
              otherIncomeCoborrower,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncomeCoborrower),
              parseMoney(selfEmploymentIncomeCoborrower),
              parseMoney(socialIncomeCoborrower),
              parseMoney(otherIncomeCoborrower),
            ].reduce((sum, value) => sum + (value || 0), 0);
            return totalIncome <= 9999999.99;
          })
          .test("min", "The value cannot be less than $10,000.", function () {
            const {
              salaryIncomeCoborrower,
              selfEmploymentIncomeCoborrower,
              socialIncomeCoborrower,
              otherIncomeCoborrower,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncomeCoborrower),
              parseMoney(selfEmploymentIncomeCoborrower),
              parseMoney(socialIncomeCoborrower),
              parseMoney(otherIncomeCoborrower),
            ].reduce((sum, value) => sum + (value || 0), 0);
            return totalIncome >= 10000;
          })
          .test(
            "min",
            "Total Annual Income must be greater than $0.",
            function () {
              const {
                salaryIncomeCoborrower,
                selfEmploymentIncomeCoborrower,
                socialIncomeCoborrower,
                otherIncomeCoborrower,
              } = this.parent;
              const totalIncome = [
                parseMoney(salaryIncomeCoborrower),
                parseMoney(selfEmploymentIncomeCoborrower),
                parseMoney(socialIncomeCoborrower),
                parseMoney(otherIncomeCoborrower),
              ].reduce((sum, value) => sum + (value || 0), 0);
              return totalIncome > 0;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      firstName: yup
        .string()
        .required("Legal first name is required.")
        .max(40, "Legal first name length must be less than or equal to 40")
        .matches(
          /^[A-Za-z\s\-',.]+$/,
          "Legal first name must contain only letters",
        ),
      lastName: yup
        .string()
        .required("Legal Last name is required.")
        .max(40, "Legal Last name length must be less than or equal to 40")
        .matches(
          /^[A-Za-zÀ-Žá-žñÑ\s'-]+$/,
          "Legal last name must contain only letters, spaces, accents, and apostrophes",
        ),
      middleName: yup
        .string()
        .max(40, "Legal Middle name length must be less than or equal to 40")
        .matches(
          /^[A-Za-zÀ-Žá-žñÑ\s-]*$/,
          "Legal Middle name must contain only letters",
        )
        .optional(),
      suffix: yup
        .string()
        .max(10, "Legal Suffix length must be less than or equal to 10")
        .matches(
          /^[A-Za-zÀ-Žá-žñÑ\s-]*$/,
          "Legal Suffix must contain only letters",
        )
        .optional(),
      homeValue: yup
        .string()
        .required("Home value is required.")
        .test("min", "Home value cannot be less than $100,000", (value) => {
          if (!value) return true;
          if (parseMoney(value) < 100_000) return false;
          return true;
        })
        .test("max", "Maximum of 9 digits allowed", (value) => {
          if (!value) return true;
          if (parseToNumbers(value).toString().length > 9) return false;
          return true;
        }),
      currentLoanBalance: yup
        .string()
        .required("Current loan balance is required")
        .test("max", "Maximum of 9 digits allowed", (value) => {
          if (!value) return true;
          if (parseToNumbers(value).toString().length > 9) return false;
          return true;
        }),
      requestedNewLoan: yup
        .string()
        .when(["currentLoanBalance"], (currentLoanBalance, schema) => {
          if (parseMoney(currentLoanBalance) === 0) {
            return schema
              .required("Request loan amount is required.")
              .test(
                "betweenAmount",
                `Loan Amount should be between $${pricingEngine?.loanMin} - $${pricingEngine?.loanMaxFirstLien}`,
                (value: string) => {
                  const amount = parseMoney(value);
                  return (
                    amount >= pricingEngine?.loanMin &&
                    amount <= pricingEngine?.loanMaxFirstLien
                  );
                },
              );
          } else {
            return schema
              .required("Request loan amount is required.")
              .test(
                "betweenAmount",
                `Loan Amount should be between $${pricingEngine?.loanMin} - $${pricingEngine?.loanMax}`,
                (value: string) => {
                  const amount = parseMoney(value);
                  return (
                    amount >= pricingEngine?.loanMin &&
                    amount <= pricingEngine?.loanMax
                  );
                },
              );
          }
        }),
      agreeNotifications: yup.boolean().required("Agree notifications."),
      ...checkboxValidations,
    });
  }, [pricingEngine, checkboxes]);
}

const useRegisterForm = () => {
  const location = useLocation();
  const querySearch = new URLSearchParams(location.search);

  const marketingCampaign = {
    ...(querySearch.get("utm_id") && {
      utm_id: querySearch.get("utm_id"),
    }),
    ...(querySearch.get("utm_source") && {
      utm_source: querySearch.get("utm_source"),
    }),
    ...(querySearch.get("utm_medium") && {
      utm_medium: querySearch.get("utm_medium"),
    }),
    ...(querySearch.get("utm_campaign") && {
      utm_campaign: querySearch.get("utm_campaign"),
    }),
    ...(querySearch.get("utm_term") && {
      utm_term: querySearch.get("utm_term"),
    }),
    ...(querySearch.get("sfmc_id") && {
      sfmc_id: querySearch.get("sfmc_id"),
    }),
  };

  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorCode, setErrorCode] = useState<string>();
  const [openedDialog, setOpenedDialog] = useState<RegisterFormDialogs>();
  const [emailAlreadyUseOnPL, setEmailAlreadyUseOnPL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvalidStateId, setIsInvalidStateId] = useState<boolean | string>(
    false,
  );
  const [checkPhoneValidation, setCheckPhoneValidation] =
    useState<PhoneValidationState>({
      isValid: false,
      failed: null,
      phoneTypeBorrower: "OTHER",
      phoneTypeCoborrower: "OTHER",
    });
  const parseToSubmit = useParseToSubmit();
  const invite = useInvite();
  const { privateLabel } = usePrivateLabel();
  const pathName = useLocation();
  const [pricingEngine, setPricingEngine] = useState<PricingEngine>();
  const signUpCheckboxes =
    privateLabel?.borrowerPortalConfiguration?.signUpCheckboxes ?? [];
  const schema = useYupValidationSchema(
    pricingEngine as PricingEngine,
    signUpCheckboxes,
  );
  const { user } = useUser();

  const [PLMasterLoanOfficer, setPLMasterLoanOfficer] = useState<
    MasterLoanOfficer | undefined
  >(undefined);

  const [showIncomeInputs, setShowIncomeInputs] = useState({
    salaryIncome: invite?.body?.incomeSourcesBorrower?.salaryIncome
      ? true
      : false,
    selfEmploymentIncome: false,
    socialIncome: false,
    otherIncome: false,
  });

  const [showIncomeInputsCoborrower, setShowIncomeInputsCoborrower] = useState({
    salaryIncomeCoborrower: false,
    selfEmploymentIncomeCoborrower: false,
    socialIncomeCoborrower: false,
    otherIncomeCoborrower: false,
  });

  const [coborrowerIncomes, setCoborrowerIncomes] = useState({
    salaryIncomeCoborrower: 0,
    selfEmploymentIncomeCoborrower: 0,
    socialIncomeCoborrower: 0,
    otherIncomeCoborrower: 0,
  });

  const [borrowerIncomes, setBorrowerIcomes] = useState({
    salaryIncome: 0,
    selfEmploymentIncome: 0,
    socialIncome: 0,
    otherIncome: 0,
  });

  const signUpCheckboxesDefaults = signUpCheckboxes
    ?.filter((checkbox) => checkbox?.key)
    ?.reduce(
      (acc, checkbox) => ({
        ...acc,
        [`signUpCheckbox_${checkbox?.key}`]: false,
      }),
      {},
    );

  const form = useForm<RegisterFormInputs>({
    mode: "onChange",
    defaultValues: {
      propertyAddress: location.state as Address,
      borrowerAddress: undefined,
      creditScore: "",
      useProceeds: "",
      occupancy: "Primary Residence",
      employmentType: "",
      email: "",
      password: "",
      firstName: "",
      phoneNumber: process.env.REACT_APP_ENV === "prod" ? "+1" : "",
      lastName: "",
      homeValue: "",
      requestedNewLoan: "",
      annualIncome: "",
      agreeNotifications: false,
      currentLoanBalance: "",
      maritalStatus: "",
      addCoborrower: "No",
      isLoggedIn: false,
      ...signUpCheckboxesDefaults,
    },
    resolver: yupResolver(schema),
  });

  const isLoggedIn = form.watch("isLoggedIn");

  useEffect(() => {
    if (user) {
      form.setValue("isLoggedIn", true);
    }
  }, [user, form, isLoggedIn]);

  useEffect(() => {
    if (user?.email) {
      form.reset({
        email: user?.email?.toLowerCase().trim(),
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
        agreeNotifications: true,
      });
    }
    if (invite && !user) {
      const incomeSourcesBorrower = invite?.body?.incomeSourcesBorrower;

      form.reset({
        propertyAddress: invite?.propertyAddress as Address,
        borrowerAddress: invite?.address as Address,
        creditScore: invite?.creditScore,
        useProceeds: invite?.useProceeds,
        occupancy: invite?.occupancy,
        employmentType: invite?.employmentType,
        maritalStatus: invite?.maritalStatus,
        email: pathName.pathname.includes("/invite")
          ? ""
          : invite?.email?.toLowerCase().trim(),
        password: "",
        firstName: invite?.firstName,
        middleName: invite?.middleName,
        suffix: invite?.suffix,
        phoneNumber: invite?.phoneNumber,
        lastName: invite?.lastName,
        homeValue:
          invite.body?.avmHomeValue?.replace("$", "$ ") ??
          invite?.homeValue?.replace("$", "$ "),
        requestedNewLoan: invite?.requestedNewLoan?.replace("$", "$ "),
        annualIncome: invite?.annualIncome?.replace("$", "$ "),
        agreeNotifications: false,
        currentLoanBalance: invite?.currentLoanBalance?.replace("$", "$ "),
        employerNameBorrower: invite?.employerNameBorrower,
        startDateBorrower: invite?.startDateBorrower,
        manual_street_number: invite?.customAddress?.street_number?.trim(),
        manual_street_name: invite?.customAddress?.street_name?.trim(),
        manual_street_suffix: invite?.customAddress?.street_suffix?.trim(),
        manual_state: invite?.customAddress?.state?.trim(),
        manual_city: invite?.customAddress?.city?.trim(),
        manual_zipcode: invite?.customAddress?.zipcode?.trim(),
        manual_apt: invite?.customAddress?.secondary?.trim(),
        manual_borrower_street_number:
          invite?.customBorrowerAddress?.street_number?.trim(),
        manual_borrower_street_name:
          invite?.customBorrowerAddress?.street_name?.trim(),
        manual_borrower_street_suffix:
          invite?.customBorrowerAddress?.street_suffix?.trim(),
        manual_borrower_state: invite?.customBorrowerAddress?.state?.trim(),
        manual_borrower_city: invite?.customBorrowerAddress?.city?.trim(),
        manual_borrower_zipcode: invite?.customBorrowerAddress?.zipcode?.trim(),
        manual_borrower_apt: invite?.customBorrowerAddress?.secondary?.trim(),
        salaryIncome:
          incomeSourcesBorrower?.salaryIncome?.toString() === ""
            ? undefined
            : incomeSourcesBorrower?.salaryIncome?.toString(),
        selfEmploymentIncome:
          incomeSourcesBorrower?.selfEmploymentIncome?.toString() === ""
            ? undefined
            : incomeSourcesBorrower?.selfEmploymentIncome?.toString(),
        socialIncome:
          incomeSourcesBorrower?.socialIncome?.toString() === ""
            ? undefined
            : incomeSourcesBorrower?.socialIncome?.toString(),
        otherIncome:
          incomeSourcesBorrower?.otherIncome?.toString() === ""
            ? undefined
            : incomeSourcesBorrower?.otherIncome?.toString(),
        ...signUpCheckboxesDefaults,
      });

      setBorrowerIcomes({
        salaryIncome: incomeSourcesBorrower?.salaryIncome ?? 0,
        selfEmploymentIncome: incomeSourcesBorrower?.selfEmploymentIncome ?? 0,
        socialIncome: incomeSourcesBorrower?.socialIncome ?? 0,
        otherIncome: incomeSourcesBorrower?.otherIncome ?? 0,
      });
      setShowIncomeInputs({
        salaryIncome: incomeSourcesBorrower?.salaryIncome ? true : false,
        selfEmploymentIncome: incomeSourcesBorrower?.selfEmploymentIncome
          ? true
          : false,
        socialIncome: incomeSourcesBorrower?.socialIncome ? true : false,
        otherIncome: incomeSourcesBorrower?.otherIncome ? true : false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite, user]);

  const onSaveUnqualified = (error?: { id: string; message: string }) =>
    form.handleSubmit(async (data) => {
      setLoading(true);
      try {
        const response = await API.post({
          url: `/save-unqualified`,
          data: {
            ...parseToSubmit(data),
            error,
            password: undefined,
          },
        });
        if ("error" in response) {
          setErrorMessage(response.error);
          setOpenedDialog("error");
        } else if (openedDialog === "MAX_OFFER_ERROR" && privateLabel) {
          setErrorMessage(
            "Unfortunately based on our underwriting guidelines we are unable to offer you a loan.\n\nPlease feel free to reach out to support.",
          );

          setOpenedDialog("error");
        } else if (openedDialog === "MAX_OFFER_ERROR") {
          setErrorMessage(
            "Unfortunately based on our underwriting guidelines we are unable to offer you a loan.\n\nPlease feel free to reach out to support:\n\nsupport@nftydoor.com",
          );

          setOpenedDialog("error");
        }
      } catch (unknownError) {
        setErrorMessage(String(unknownError));
        console.error(unknownError);
      }
      setLoading(false);
    })();

  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);

    if (!invite?.isMobile) {
      const phoneNumbers = {
        phoneBorrower: data.phoneNumber,
        phoneCoborrower: data.phoneCoborrower,
      };

      const queryString = qs.stringify(phoneNumbers);

      try {
        const response = (await API.get(
          `/getServiceUnsecured/validate-phone-number?${queryString}`,
        )) as CheckPhone;

        const checkPhone = response.data;
        setCheckPhoneValidation(checkPhone);
        if (!checkPhone?.isValid) {
          const failedField =
            checkPhone?.failed === "phoneBorrower"
              ? "phoneNumber"
              : "phoneCoborrower";
          form.setError(failedField, {
            type: "manual",
            message: `Please provide a valid phone number for ${
              failedField === "phoneNumber" ? "the borrower" : "the co-borrower"
            } to continuos with the application.`,
          });
          return;
        }
      } catch (error) {
        setErrorMessage(`Error validating phone numbers: ${error}`);
      }
    }
    let annualIncome = 0;
    let annualIncomeCoborrower = 0;

    const borrowerIncomes = [
      data?.salaryIncome,
      data?.selfEmploymentIncome,
      data?.socialIncome,
      data?.otherIncome,
    ].map((value: string | undefined) => {
      if (value) {
        return parseMoney(value);
      }
      return 0;
    });

    annualIncome = [...borrowerIncomes].reduce((acc, curr) => acc + curr, 0);

    const annualIncomeToString = annualIncome.toString();

    let annualIncomeCoborrowerToString;

    if (data?.legalFirstNameCoborrower) {
      const coborrowerIncomes = [
        data?.salaryIncomeCoborrower,
        data?.selfEmploymentIncomeCoborrower,
        data?.socialIncomeCoborrower,
        data?.otherIncomeCoborrower,
      ].map((value: string | undefined) => {
        if (value) {
          return parseMoney(value);
        }
        return 0;
      });
      annualIncomeCoborrower = [...coborrowerIncomes].reduce(
        (acc, curr) => acc + curr,
        0,
      );

      annualIncomeCoborrowerToString = annualIncomeCoborrower.toString();
    }

    const newData = {
      ...data,
      annualIncome: annualIncomeToString,
      annualIncomeCoborrowerToString,
    };

    const dataSending = {
      ...parseToSubmit(newData),
      ...(querySearch.get("utm_id") && { marketingCampaign }),
      loanOfficerId: privateLabel?.isNFTYDoor
        ? undefined
        : invite?.loanOfficer?.id,
      PLMasterLoanOfficer,
      isB1Mobile:
        checkPhoneValidation?.phoneTypeBorrower === "MOBILE" ||
        checkPhoneValidation?.phoneTypeBorrower === "VOIP",
      isB2Mobile: data?.addCoborrower
        ? checkPhoneValidation?.phoneTypeCoborrower === "MOBILE" ||
          checkPhoneValidation?.phoneTypeCoborrower === "VOIP"
        : undefined,
      isLoggedIn,
    };

    try {
      const response = await API.post<Data>({
        url: `/register-user/borrower?ignoreMaxDti=true`,
        data: dataSending,
      });
      if ("error" in response) {
        if (response.errorId === "EXISTANT_USER_NOT_LOGGED") {
          setErrorMessage(response.error);
          setOpenedDialog("EXISTANT_USER_NOT_LOGGED");
        } else {
          setOpenedDialog(
            response.errorId === "MAX_OFFER_ERROR"
              ? "MAX_OFFER_ERROR"
              : "error",
          );
          setErrorMessage(response.error);
          setErrorCode(response.errorId);

          response.errorId !== "MAX_OFFER_ERROR" &&
            onSaveUnqualified(
              response.errorId
                ? {
                    id: response.errorId ?? "",
                    message: response.error ?? "",
                  }
                : undefined,
            );
        }
      } else if (response?.data?.userAccountNoNeedVerification?.message) {
        setEmailAlreadyUseOnPL(
          response?.data?.userAccountNoNeedVerification?.message
            ?.toLowerCase()
            .trim(),
        );
        setOpenedDialog("EMAIL_IN_USE_ON_PL");
      } else {
        setOpenedDialog("verify");
      }
    } catch (unknownError) {
      if (
        unknownError instanceof TypeError &&
        unknownError.message === "Load failed"
      ) {
        setErrorMessage("Session timeout. Please refresh and retry.");
      } else {
        setErrorMessage(String(unknownError));
        console.error(unknownError);
      }
    }
    setLoading(false);
  });

  const currentManualState = form.watch("manual_state");
  const currentPropertyAddress = form.watch("propertyAddress");
  const currentOccupancy = form.watch("occupancy");

  useEffect(() => {
    const currentStreetLine = currentPropertyAddress?.street_line;
    const statesLicensed = privateLabel?.statesLicensed;

    function handleListedAddrees() {
      const currentState = currentPropertyAddress?.state;
      if (currentState && statesLicensed) {
        const isStateLicensed = statesLicensed.find(
          (license) =>
            license.state_id.toUpperCase() === currentState.toUpperCase(),
        );

        if (currentState.length > 1 && !isStateLicensed) {
          setIsInvalidStateId(
            `The company is not authorized to lend in this State: ${currentState}`,
          );
        } else if (
          currentState === "NY" &&
          privateLabel?.newYorkApplicationsDomain &&
          !privateLabel?.newYorkApplicationsDomain?.includes(
            window.location.hostname,
          )
        ) {
          setIsInvalidStateId(
            `The company is not authorized to operate in this state: ${currentState}, Please hold as we need to direct you to our NY application site ${privateLabel.newYorkApplicationsDomain}`,
          );

          setTimeout(() => {
            window.location.href = `${privateLabel.newYorkApplicationsDomain}${location.pathname}${location.search}`;
          }, 5000);
        } else {
          setIsInvalidStateId(false);
        }
      }
    }

    function handleNotListedAddrees() {
      if (currentManualState && statesLicensed) {
        const isStateLicensed = statesLicensed.find(
          (license) =>
            license.state_id.toUpperCase() === currentManualState.toUpperCase(),
        );

        if (currentManualState.length > 1 && !isStateLicensed) {
          setIsInvalidStateId(
            `The company is not authorized to lend in this State: ${currentManualState}`,
          );
        } else if (
          currentManualState === "NY" &&
          privateLabel?.newYorkApplicationsDomain &&
          !privateLabel?.newYorkApplicationsDomain?.includes(
            window.location.hostname,
          )
        ) {
          setIsInvalidStateId(
            `The company is not authorized to operate in this state: ${currentManualState}, Please hold as we need to direct you to our NY application site ${privateLabel.newYorkApplicationsDomain}`,
          );

          setTimeout(() => {
            window.location.href = `${privateLabel.newYorkApplicationsDomain}${location.pathname}${location.search}`;
          }, 5000);
        } else {
          setIsInvalidStateId(false);
        }
      }
    }

    if (currentStreetLine !== "My address is not listed") {
      handleListedAddrees();
    } else if (currentStreetLine === "My address is not listed") {
      handleNotListedAddrees();
    } else {
      setIsInvalidStateId(false);
    }
  }, [
    currentManualState,
    currentPropertyAddress?.state,
    currentPropertyAddress?.street_line,
    privateLabel,
    location.pathname,
    location.search,
  ]);

  useEffect(() => {
    const privateLabelId = privateLabel?.id;
    const helocType = handleOcuppancy(currentOccupancy);
    const pricingEngineId = `${privateLabelId}#${helocType}`;
    const encodePricingEngineId = encodeURIComponent(pricingEngineId);

    API.get<PricingEngine>(
      `/get-heloc-pricing-engine?id=${encodePricingEngineId}`,
    ).then((result) => {
      if ("error" in result) {
        return;
      } else {
        setPricingEngine(result.data);
      }
    });
  }, [currentOccupancy, privateLabel]);

  return {
    onSaveUnqualified,
    onSubmit,
    openedDialog,
    errorMessage,
    setOpenedDialog,
    emailAlreadyUseOnPL,
    setErrorMessage,
    errorCode,
    loading,
    isInvalidStateId,
    pricingEngine,
    setPLMasterLoanOfficer,
    showIncomeInputs,
    setShowIncomeInputs,
    borrowerIncomes,
    setBorrowerIcomes,
    showIncomeInputsCoborrower,
    setShowIncomeInputsCoborrower,
    coborrowerIncomes,
    setCoborrowerIncomes,
    checkPhoneValidation,
    ...form,
  };
};

export default useRegisterForm;
