import { Box } from "@mui/system";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import "./styles.scss";
interface HBSeparateProps {
  className?: string;
  type?: "horizontal" | "vertical";
}

export const HBSeparate = ({
  className = "",
  type = "horizontal",
}: HBSeparateProps) => {
  const { tertiaryText } = useHomeBridgeColors();
  const typeClassName = {
    vertical: "hb-separate--vertical",
    horizontal: "hb-separate--horizontal",
  };
  return (
    <Box
      className={`hb-separate ${className} ${typeClassName[type]}`}
      sx={{
        display: "inline-block",
        border: `1px solid ${tertiaryText}`,
      }}
    />
  );
};
