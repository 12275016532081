import { Box } from "@mui/material";
import { CircleCheck } from "components/icons/CircleCheck";
import React from "react";

interface ProgressBarProps {
  progress: number;
}

export const ProgressBar = ({ progress }: ProgressBarProps) => {
  const isShowCircle = progress === 100;
  return (
    <Box
      display={"flex"}
      className="progress-bar__container"
      sx={{
        width: "100%",
      }}
    >
      <Box
        className="progress-bar"
        sx={{
          width: "85%",
        }}
      >
        <Box className="progress" style={{ width: `${progress}%` }}></Box>
      </Box>
      {isShowCircle && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <CircleCheck />
        </Box>
      )}

      <Box
        className="progress-bar"
        sx={{
          width: "15%",
          marginLeft: "5px",
        }}
      >
        <Box className="progress" style={{ width: 0 }}></Box>
      </Box>
    </Box>
  );
};
