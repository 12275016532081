import "./styles.scss";
import { useCallback, useState } from "react";
import API from "utils/API";
import { Address } from "./Address";
import { Property } from "./Property";
import { LoanRequest } from "./LoanRequest";
import { CreditScore } from "./CreditScore";
import { Offer } from "./Offer";
import { Box } from "@mui/material";

export interface FormDataNumbers {
  homeValue?: number;
  currentLoanBalance?: number;
  creditScore?: number;
  annualIncome?: number;
  requestedLoanAmount?: number;
}

export interface FormData extends FormDataNumbers {
  occupancyType?: string;
  countyZIP: string;
  loanPurpose: string;
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  source: string;
  entries?: number;
}
export interface OfferResponse {
  monthlyPayment: number;
  interestRate: number;
  maxOffer: number;
  APR: number;
  minOffer: number;
  amount: number;
  netFundingWiredToBank: number;
  debtToIncomeRatio: number;
  cltv: number;
  monthlyInterestCharge: number;
  marginRate: number;
  primeRate: number;
  initialDrawAmount: number;
  minimumInitialDraw: number;
  maximumInitialDraw: number;
  loanCountyFIPS: string;
  pricingEngineId: string;
  pricingEngineCreatedAt: string;
  appliedAdjustors: number;
  monthlyPaymentInitialDraw: number;
  loanPurpose: string;
  occupancyType: string;
  address: {
    city: string;
    state: string;
    street_line: string;
    secondary: string;
    zipcode: string;
    fullAddress?: string;
  };
}
export const GetStarted = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    homeValue: undefined,
    currentLoanBalance: undefined,
    creditScore: undefined,
    annualIncome: 1,
    occupancyType: undefined,
    countyZIP: "",
    requestedLoanAmount: undefined,
    loanPurpose: "",
    street_line: "",
    secondary: "",
    city: "",
    state: "",
    zipcode: "",
    entries: 0,
    source: "postal",
  });

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);
  const [offer, setOffer] = useState<OfferResponse[]>();

  const getOffer = useCallback(async () => {
    setLoading(true);
    setError(null);
    const parseData = Object.entries(formData)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const queryString = new URLSearchParams(parseData).toString();
    try {
      const url = `/getServiceUnsecured/pricing-engine-heloc?${queryString}`;
      const response = await API.get(url);
      if ("error" in response) {
        // @ts-ignore
        if (response?.errorId?.message) {
          setLoading(false);
          // @ts-ignore
          setError(response?.errorId?.message);
          return;
        }
        setLoading(false);
      } else {
        setOffer(response?.data as OfferResponse[]);
        setLoading(false);
        nextStep();
      }
    } catch (error) {
      console.error(error);
    }
  }, [formData]);

  const handleGetOffer = useCallback(() => {
    getOffer();
  }, [getOffer]);
  const stepHandler: { [key: number]: JSX.Element } = {
    1: (
      <Address
        formData={formData}
        onNext={nextStep}
        setFormData={setFormData}
      />
    ),
    2: (
      <Property
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    3: (
      <LoanRequest
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    4: (
      <CreditScore
        error={error}
        loading={loading}
        formData={formData}
        onNext={handleGetOffer}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    5: (
      <Offer
        offer={offer}
        error={error}
        loading={loading}
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
  };
  const currentStep = stepHandler[step];

  return <Box className="get-started">{currentStep}</Box>;
};
