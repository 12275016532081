import { Box } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { CHBLink } from "components/CHBLink";
import "./styles.scss";
import { HBSeparate } from "components/HBSeparate";
import { useLocation, useNavigate } from "react-router-dom";

interface LinkListProps {
  className?: string;
}
export const LinkList = ({ className = "" }: LinkListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const isGetStarted = pathName.includes("/get-started");
  return (
    <Box
      className={`link-list ${className}`}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box className="link-list__desktop">
        <CHBLink to="/" separate>
          Home
        </CHBLink>
        <CHBLink to="/faq" separate>
          FAQ
        </CHBLink>
        <CHBLink to="/resources" separate>
          RESOURCES
        </CHBLink>
        <CHBLink to="/login">LOGIN</CHBLink>
        {!isGetStarted && (
          <CHBButton
            onClick={() => {
              navigate("/get-started");
            }}
            styleContainer={{
              maxWidth: "130px",
            }}
            style={{
              padding: "5px 30px",
              width: "130px",
            }}
          >
            APPLY
          </CHBButton>
        )}
      </Box>
      <Box className="link-list__mobile">
        <CHBLink to="/">Home</CHBLink>
        <HBSeparate type="vertical" />
        <CHBLink to="/faq">FAQ</CHBLink>
        <HBSeparate type="vertical" />
        <CHBLink to="/resources">RESOURCES</CHBLink>
        <HBSeparate type="vertical" />
        <CHBLink to="/login">LOGIN</CHBLink>
        {!isGetStarted && (
          <CHBButton
            onClick={() => {
              navigate("/get-started");
            }}
            styleContainer={{
              maxWidth: "130px",
            }}
            style={{
              padding: "5px 30px",
              width: "130px",
            }}
          >
            APPLY
          </CHBButton>
        )}
      </Box>
    </Box>
  );
};
