import { Box, Typography, TypographyProps } from "@mui/material";

interface CustomTextProps extends TypographyProps {
  label: string;
  value: string;
}

export const CustomText = ({ label, value, ...props }: CustomTextProps) => {
  return (
    <Typography
      alignItems={"center"}
      {...props}
      sx={{
        display: "flex",
        gap: "10px",
        fontWeight: 900,
        textAlign: "left",
        position: "relative",
        color: "#20A2D8",
        zIndex: 1,
      }}
      fontSize={{
        xs: "18px",
        sm: "20px",
        md: "20px",
        lg: "22px",
        xl: "24px",
      }}
    >
      {label}: {""}
      <Box component="span" sx={{ fontWeight: 700, color: "white" }}>
        {value}
      </Box>
    </Typography>
  );
};
