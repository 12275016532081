import { Box, Typography } from "@mui/material";
import CButton from "components/CButton";
import { useDebtToIncomePlaid } from "./useDebtToIncomePlaid";
import { useDebtToIncomeModal } from "../useDebtToIncomeModal";
import color from "context/MaterialUITheme/color";
import Loan from "typedef/Loan";
import { useTheme } from "@mui/system";

interface DebtToIncomeProps {
  handleButton: () => void;
  selfEmployed: boolean;
  loading: boolean;
  step?: Loan["borrowerSteps"] | Loan["coborrowerSteps"];
}

export const DebtToIncomePlaid = ({
  handleButton,
  loading,
  step,
  selfEmployed,
}: DebtToIncomeProps) => {
  const { styles } = useDebtToIncomePlaid();
  const { styles: detbToIncomeStyles } = useDebtToIncomeModal();
  const theme = useTheme();
  const isErrorMessage = step?.incomeVerification?.errorMessagePlaid;
  const accountWithoutName =
    step?.incomeVerification.baseReport?.bankAccounts.find(
      (account) =>
        !account?.owners ||
        !account?.owners?.length ||
        (account?.owners?.[0] &&
          (account?.owners?.[0].names?.[0] === "" ||
            account?.owners?.[0].names?.[0] === undefined)),
    );
  const accountWithoutMask =
    step?.incomeVerification.baseReport?.bankAccounts.find(
      (account) => account.mask === undefined || account.mask === "",
    );
  return (
    <Box sx={styles.containerPlaid}>
      <Typography
        component={"h5"}
        variant="h5"
        color={color.black}
        fontWeight={700}
      >
        Link your Personal Checking Account (Fast!)
      </Typography>
      <Typography sx={{ ...detbToIncomeStyles.text, mb: 2 }}>
        Connect your personal checking account(s) via Plaid. The process is
        simple, secure, and provides instant results! Please note that the
        following banks are currently not available: National Bank, TD Bank, and
        Citizens. Everyone listed on the connected bank account must also be
        added to your HELOC application.
      </Typography>

      <CButton
        variant="contained"
        loading={loading}
        sx={detbToIncomeStyles.buttonStyles}
        onClick={handleButton}
        name="debtToIncomePlaid-linkPersonalAccount"
        aria-label="debtToIncomePlaid-linkPersonalAccount"
      >
        Link Personal Account
      </CButton>
      {isErrorMessage &&
        isErrorMessage !== "Processing… Please give us a moment" && (
          <Typography
            color={color.red}
            sx={{
              p: 1,
            }}
          >
            {isErrorMessage}
          </Typography>
        )}
      {selfEmployed &&
        step?.incomeVerification.plaidWasRun &&
        (accountWithoutMask || accountWithoutName) && (
          <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
            <CButton
              variant="contained"
              loading={loading}
              sx={{ ...detbToIncomeStyles.buttonStyles, flex: 2 }}
              onClick={handleButton}
              name="debtToIncomePlaid-updateAccount"
              aria-label="debtToIncomePlaid-updateAccount"
            >
              Update your account information
            </CButton>

            <Typography
              aria-label="debtToIncomePlaid-updateAccount"
              fontSize={13}
              flex={2}
            >
              <Typography color={"red"} fontSize={14}>
                We had an issue connecting to your account.
              </Typography>
              Please click the{" "}
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                Update Your Account Information
              </span>{" "}
              button to update your account information.
            </Typography>
          </Box>
        )}
    </Box>
  );
};
