import CDialog from "components/CDialog";
import { Box, Button, Typography } from "@mui/material";

const ChangeB2ToNPEModal = ({
  openModalB2toNPE,
  handleCloseModalB2ToNPE,
}: {
  openModalB2toNPE: boolean;
  handleCloseModalB2ToNPE: () => void;
}) => {
  return (
    <CDialog
      fullWidth
      maxWidth="xs"
      scroll={"paper"}
      open={openModalB2toNPE}
      onClose={handleCloseModalB2ToNPE}
      disableEscapeKeyDown
      hideBackdrop
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        gap={1}
        width={"100%"}
        display={"flex"}
        alignItems={"start"}
        justifyContent={"start"}
        flexDirection={"column"}
      >
        <Box
          mb={4}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            fontFamily={"Pro Display Bold"}
            variant="h6"
            textAlign={"left"}
          >
            Your income is approved, we’re removing your coborrower and adding
            them as a signer.
          </Typography>
        </Box>
        <Button
          data-cy="closeB2ToNPEModal"
          aria-label={`Close`}
          variant="contained"
          sx={{
            whiteSpace: "nowrap",
            borderRadius: "25px",
            textTransform: "inherit",
            width: "300px",
            margin: "auto",
          }}
          onClick={handleCloseModalB2ToNPE}
        >
          Close
        </Button>
      </Box>
    </CDialog>
  );
};

export default ChangeB2ToNPEModal;
