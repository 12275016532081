import { ReactNode, useEffect } from "react";
import {
  SimplePaletteColorOptions,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

export const MainThemeUI: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { privateLabel } = usePrivateLabel();
  const { isHomeBridge } = useIsHomebridge();
  const {
    primaryText,
    primaryBackground,
    secondaryBackground,
    secondaryText,
    tertiaryText,
  } = useHomeBridgeColors();

  const theme = createTheme({
    palette: {
      ...privateLabel?.pallete,
      primary: {
        main: isHomeBridge
          ? tertiaryText
          : (privateLabel?.pallete?.primary as SimplePaletteColorOptions).main,
      },
      background: {
        paper: isHomeBridge
          ? primaryBackground
          : privateLabel?.pallete?.background?.paper,
        default: isHomeBridge
          ? secondaryBackground
          : privateLabel?.pallete?.background?.default,
      },
      text: {
        primary: isHomeBridge
          ? secondaryText
          : privateLabel?.pallete?.text?.primary,
        secondary: isHomeBridge
          ? primaryText
          : privateLabel?.pallete?.text?.secondary,
      },
    },
    typography: {
      fontFamily: [
        '"Pro Display Regular"',
        '"Pro Text Semibold"',
        '"Pro Display Bold"',
        '"Pro Display Semibold"',
        '"Pro Display Medium"',
      ].join(","),
      h1: { fontFamily: "Pro Display Bold" },
      h2: { fontFamily: "Pro Display Semibold" },
      h3: { fontFamily: "Pro Display Semibold" },
      h4: { fontFamily: "Pro Display Semibold" },
      h5: { fontFamily: "Pro Display Regular" },
      h6: { fontFamily: "Pro Display Regular" },
      subtitle1: { fontFamily: "Pro Display Regular" },
      subtitle2: { fontFamily: "Pro Display Regular" },
      body1: {
        fontFamily: isHomeBridge ? "NunitoSans" : "Pro Display Regular",
      },
      body2: { fontFamily: "Pro Display Regular" },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: isHomeBridge
              ? primaryText
              : privateLabel?.pallete?.text?.secondary,
            borderRadius: "12px",
            background: privateLabel?.pallete?.background?.paper,
            fontFamily: "Pro Display Regular",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            borderColor: isHomeBridge
              ? tertiaryText
              : privateLabel?.pallete?.background?.paper,
            backgroundColor: isHomeBridge
              ? tertiaryText
              : (privateLabel?.pallete?.primary as SimplePaletteColorOptions)
                  .main,
            borderWidth: "0.01rem !important",
            color: isHomeBridge ? primaryBackground : primaryText,
            ":hover": {
              backgroundColor: isHomeBridge
                ? "white"
                : (privateLabel?.pallete?.primary as SimplePaletteColorOptions)
                    ?.light,
            },
          },
          outlined: {
            borderColor: isHomeBridge
              ? tertiaryText
              : (privateLabel?.pallete?.primary as SimplePaletteColorOptions)
                  ?.main,
            backgroundColor: isHomeBridge
              ? tertiaryText
              : privateLabel?.pallete?.background?.paper,
            borderWidth: "0.01rem !important",
            ":hover": {
              backgroundColor: isHomeBridge
                ? "white"
                : (privateLabel?.pallete?.primary as SimplePaletteColorOptions)
                    ?.light,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthXs: {
            maxWidth: 410,
          },
          paperWidthMd: {
            maxWidth: 500,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& legend": {
              width: "0",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          formControl: {
            position: "relative",
          },
          root: {
            transform: "unset !important",
            padding: "0 0.2rem",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            gap: "4px",
          },
        },
      },
    },
  });
  useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty(
      "--palette-text-primary",
      `${isHomeBridge ? primaryText : privateLabel?.pallete?.text?.secondary}`,
    );
    root.style.setProperty(
      "--palette-background-paper",
      `${privateLabel?.pallete?.background?.paper}`,
    );
    root.style.setProperty(
      "--palette-text-secondary",
      `${
        isHomeBridge ? secondaryText : privateLabel?.pallete?.text?.secondary
      }`,
    );
    root.style.setProperty(
      "--palette-background-default",
      `${privateLabel?.pallete?.background?.default}`,
    );
  }, [privateLabel?.pallete, isHomeBridge, primaryText, secondaryText]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
