import { Box, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { Ocuppancy } from "context/Invite/useInviteContext";
import { useState } from "react";
import { ProgressBar } from "../progressBar";
import { FormData } from "..";
import { PrimaryResidence } from "components/icons/PrimaryResidence";
import { InvestmentProperty } from "components/icons/InvestmentProperty";
import { SecondHome } from "components/icons/SecondHome";

export interface PropertyProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const Property = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const handleNext = () => {
    if (!formData.occupancyType) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      onNext();
    }
  };

  const handleSelect = (type: Ocuppancy) => {
    setFormData({ ...formData, occupancyType: type });
    setCurrentActive(type);
    setError(false);
  };
  const [currentActive, setCurrentActive] = useState(formData.occupancyType);

  return (
    <Box>
      <div className="get-started__wrapper get-started__property">
        <Box>
          <Typography
            variant="h2"
            fontFamily={"NunitoSans"}
            fontSize={{
              xs: "32px",
              sm: "38px",
              md: "38px",
              lg: "40px",
              xl: "40px",
            }}
            textAlign={"center"}
            marginTop={"20px"}
            marginBottom={"20px"}
            fontWeight={900}
          >
            Tell us about this property:
          </Typography>

          <ProgressBar progress={25} />
        </Box>
      </div>
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "",
              sm: "repeat(3, 1fr)",
            },
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <CHBButton
            onClick={() => handleSelect("Primary Residence")}
            className={`animated-button ${
              currentActive === "Primary Residence"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <PrimaryResidence />
            <p className="text-black">Full-Time Residence</p>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Second Home")}
            className={`animated-button ${
              currentActive === "Second Home" ? "animated-button--active" : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <SecondHome />
            <p className="text-black">Second Home</p>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Investment Property")}
            className={`animated-button ${
              currentActive === "Investment Property"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <InvestmentProperty />
            <p className="text-black"> Investment Property</p>
          </CHBButton>
        </Box>
        {/* {error && (
          <p style={{ color: "red" }}>Please select a property type.</p>
        )} */}
        <p className="get-started__note">
          Knowing this will help us determine your available line of credit.
        </p>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <CHBButton
            onClick={onPrev}
            classNameContainer="button-prev"
            className="animated-button"
          >
            Prev
          </CHBButton>
          <CHBButton onClick={handleNext}>Next</CHBButton>
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
