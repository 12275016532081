import CButton from "components/CButton";
import CDialog from "components/CDialog";
import CTextField from "components/CTextField";
import useDocumentUploadForm from "./useDocumentUploadForm";
import CDialogTransition from "components/CDialogTransition";
import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { MissingDocuments } from "typedef/MissingDocuments";
import moment from "moment";
import useLoan from "components/CTracker/useLoan";

export type NeededDoc = {
  docName: string;
  submitted: boolean;
  isBorrower: boolean;
  step: keyof MissingDocuments;
  rejected?: boolean;
  rejectReason?: string;
  yearRequestedByAdmin?: string;
  companyName?: string;
  lienBalance?: number;
  payOffId?: string;
  paidAndClosedLetter?: boolean;
};
type DialogProps = {
  open: boolean;
  handleClose: () => void;
  neededDocs: NeededDoc[];
  singleDocument?: boolean;
};

const stepWithOutB2 = ["titleVerification", "insuranceVerification"];

const CDocUploadDocumentDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  neededDocs,
  singleDocument,
}) => {
  const theme = useTheme();
  const form = useDocumentUploadForm(handleClose);
  const [listDocs, setListDocs] = useState<NeededDoc[]>(neededDocs);

  const loan = useLoan();

  useEffect(() => {
    setListDocs(
      neededDocs?.filter(
        (doc) => doc.submitted === false || doc?.rejected === true,
      ),
    );
  }, [neededDocs]);

  const handleDocumentClick = (document: NeededDoc) => {
    form.setValue("selectedDocument", document);
  };

  useEffect(() => {
    if (singleDocument) form.setValue("selectedDocument", listDocs[0]);
  }, [listDocs, singleDocument, form]);

  const selectedDocument = form.getValues("selectedDocument");

  const filteredSteps = stepWithOutB2.includes(selectedDocument?.step);
  const borrowerType = selectedDocument?.isBorrower
    ? "Borrower"
    : "Co-borrower";
  const defaultValue = filteredSteps
    ? selectedDocument?.docName
    : `${selectedDocument?.docName} ${borrowerType}`;

  const [years, setYears] = useState<string[]>([]);

  const incomeStep = selectedDocument?.isBorrower
    ? loan?.borrowerSteps?.incomeVerification
    : loan?.coborrowerSteps?.incomeVerification;
  const taxReturns = incomeStep?.taxReturns;
  useEffect(() => {
    const priorOneYear = 1;
    const priorTwoYears = 2;
    const priorThreeYears = 3;
    const year1 = moment()
      .subtract(priorThreeYears, "year")
      .get("year")
      .toString();
    const year2 = moment()
      .subtract(priorTwoYears, "year")
      .get("year")
      .toString();
    const year3 = moment()
      .subtract(priorOneYear, "year")
      .get("year")
      .toString();
    let yearsForSelect = [year1, year2, year3];

    if (taxReturns) {
      yearsForSelect = yearsForSelect.filter(
        (year) => !taxReturns.find((taxReturn) => taxReturn?.taxYear === year),
      );
    }

    setYears(yearsForSelect);
  }, [taxReturns]);

  const isTaxReturn = form
    .watch("selectedDocument.docName")
    ?.includes("Tax return");

  const isAdditionalTaxReturn = form
    .watch("selectedDocument.docName")
    ?.includes("Additional Tax Return");

  return (
    <CDialog
      maxWidth="md"
      title={`Upload Missing ${singleDocument ? "Document" : "Documents"}`}
      open={open}
      onClose={handleClose}
      TransitionComponent={CDialogTransition}
      aria-labelledby="customized-dialog-title"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Typography mb={2} color={theme.palette.text.secondary} fontStyle={""}>
        Please upload {singleDocument ? "your document" : "your documents"} in
        PDF format
      </Typography>
      <form onSubmit={form.uploadDocument}>
        <Controller
          control={form.control}
          name="selectedDocument.docName"
          render={({ field }) => {
            return singleDocument ? (
              <Typography variant="h5" mb={3}>
                {defaultValue}
              </Typography>
            ) : (
              <CTextField
                {...field}
                select={singleDocument ? undefined : true}
                fullWidth
                required
                sx={{
                  mb: 2,
                  textAlign: "left",
                }}
                error={form.formState.errors.selectedDocument?.docName?.message}
                label="Missing Docs"
              >
                {listDocs &&
                  listDocs.map((doc) => {
                    const filteredStepOptions = stepWithOutB2.includes(
                      doc?.step,
                    );
                    const isBorrower = doc.isBorrower
                      ? "Borrower"
                      : "Co-borrower";
                    const documentName = filteredStepOptions
                      ? doc.docName
                      : `${doc.docName} ${isBorrower}`;
                    return (
                      <MenuItem
                        onClick={() => handleDocumentClick(doc)}
                        key={documentName}
                        value={documentName}
                      >
                        {documentName}
                      </MenuItem>
                    );
                  })}
              </CTextField>
            );
          }}
        />
        {isTaxReturn && (
          <CTextField
            select
            id="tax-year"
            fullWidth
            defaultValue={years[0]}
            sx={{
              mb: 2,
              textAlign: "left",
            }}
            error={form?.formState?.errors?.taxYear?.message}
            label="Tax Year"
            {...form.register("taxYear", {
              required: {
                value: true,
                message: "Tax Year is required",
              },
            })}
          >
            {years ? (
              years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">Select</MenuItem>
            )}
          </CTextField>
        )}
        {isAdditionalTaxReturn && (
          <CTextField
            select
            fullWidth
            defaultValue={form?.watch("selectedDocument.yearRequestedByAdmin")}
            sx={{
              mb: 2,
              textAlign: "left",
            }}
            label="Tax Year Requested:"
            {...form.register("taxYear", {
              required: {
                value: true,
                message: "Tax Year is required",
              },
            })}
          >
            <MenuItem
              value={form?.getValues("selectedDocument.yearRequestedByAdmin")}
            >
              {form?.getValues("selectedDocument.yearRequestedByAdmin")}
            </MenuItem>
          </CTextField>
        )}

        <CTextField
          fullWidth
          sx={{
            pb: 2,
          }}
          error={form.formState.errors.document?.message}
          type={"file"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            form.convertToBase64(e);
          }}
          inputProps={{
            accept: [".pdf"],
            ignore: ".svg",
          }}
        />
        <Typography sx={{ textAlign: "left", fontSize: "0.8rem" }}>
          Maximum file size allowed: 100MB
        </Typography>
        {form.errorSubmit && (
          <Typography marginTop={2} color={theme.palette.error.main}>
            {form.errorSubmit}
          </Typography>
        )}
        <Box flexDirection={"row"} display={"flex"} mt={4}>
          <CButton
            type="reset"
            fullWidth
            variant="outlined"
            sx={{ mr: 2 }}
            disabled={form.loading}
            onClick={() => form.onReset()}
            name="missingDocsDialog-discard"
          >
            Discard
          </CButton>
          <CButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ ml: 2 }}
            loading={form.loading}
            name="missingDocsDialog-upload"
            data-cy="missingDocsDialog-upload"
          >
            Upload
          </CButton>
        </Box>
      </form>
    </CDialog>
  );
};

export default CDocUploadDocumentDialog;
