import { Box, Container, Typography } from "@mui/material";
import { useState } from "react";
import { env } from "env";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { Logo } from "components/HBHeader/Nav/Logo";
import { LinkList } from "components/HBHeader/Nav/LinkList";

export const HBFooter = () => {
  const [enviromentSelected, setEnviroment] = useState(
    localStorage.getItem("currentEviroment") ?? "DEV",
  );
  const { primaryBackground, primaryText } = useHomeBridgeColors();
  return (
    <Box
      className="hb-footer"
      sx={{
        backgroundColor: primaryBackground,
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Container className="hb-footer__container">
        <Box
          className="hb-footer_logo"
          display="flex"
          justifyContent="center"
          sx={{
            padding: "10px 0",
          }}
        >
          <Logo />
        </Box>

        <LinkList className="hb-footer__footer" />
        {process.env.REACT_APP_ENV === "prod" ? null : (
          <Box>
            <Box display="flex" justifyContent="end">
              <Typography color={primaryText}>
                Current version: {process.env.REACT_APP_CURRENT_VERSION}{" "}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="end">
              <select
                value={enviromentSelected}
                onChange={(e) => {
                  localStorage.setItem("currentEviroment", e.target.value);
                  setEnviroment(e.target.value);
                  window.location.reload();
                }}
                onSelect={(e) => {
                  console.log(e);
                }}
              >
                {Object.keys(env).map((items, index) => (
                  <option key={index} value={items}>
                    {items}
                  </option>
                ))}
              </select>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};
