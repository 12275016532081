import { Box, Container, Typography } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { HellaFast } from "./HellaFast";
import { useNavigate } from "react-router-dom";

export const HBHome = () => {
  const navigate = useNavigate();
  const { primaryText } = useHomeBridgeColors();
  const typographySize = {
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  };
  return (
    <Container>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={"30px"}
        flexDirection={"column"}
      >
        <HellaFast />
        <Typography
          variant="body1"
          color={primaryText}
          fontSize={{
            xs: "18px",
            sm: "22px",
            md: "24px",
            lg: "28px",
            xl: "30px",
          }}
          marginTop="20px"
          fontWeight={"bold"}
        >
          Get the cash you need, whenever you need it
        </Typography>
        <CHBButton
          onClick={() => {
            navigate("/get-started");
          }}
          variant="contained"
          color="primary"
          size="large"
          styleContainer={{
            marginTop: "20px",
            maxWidth: "200px",
          }}
        >
          GET STARTED
        </CHBButton>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          marginTop="20px"
        >
          <Typography
            variant="body1"
            color={primaryText}
            fontSize={typographySize}
          >
            2 minutes
          </Typography>
          <HBSeparate />
          <Typography
            variant="body1"
            color={primaryText}
            fontSize={typographySize}
          >
            0 credit impact
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
