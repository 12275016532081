import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import "./my-loans.scss";

const IconButtons = ({
  handleOpen,
  handleNavigate,
  redirectText,
}: {
  handleOpen: () => void;
  handleNavigate: () => void;
  redirectText: string;
}) => {
  const { privateLabel } = usePrivateLabel();

  return (
    <ul
      className={`${
        privateLabel?.isNFTYDoor ? "container__icons" : "container__icons__pl"
      }`}
    >
      <li onClick={handleOpen} className="icon-content">
        <div className="link" onClick={handleOpen}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="#3E3E65"
            fill="none"
          >
            <path
              d="M21.544 11.045C21.848 11.4713 22 11.6845 22 12C22 12.3155 21.848 12.5287 21.544 12.955C20.1779 14.8706 16.6892 19 12 19C7.31078 19 3.8221 14.8706 2.45604 12.955C2.15201 12.5287 2 12.3155 2 12C2 11.6845 2.15201 11.4713 2.45604 11.045C3.8221 9.12944 7.31078 5 12 5C16.6892 5 20.1779 9.12944 21.544 11.045Z"
              stroke="currentColor"
              strokeWidth="1.5"
            />
            <path
              d="M15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12Z"
              stroke="currentColor"
              strokeWidth="1.5"
            />
          </svg>
        </div>
        <div data-cy="seeMore" className="tooltip">
          See more
        </div>
      </li>
      <li className="icon-content">
        <div
          className="link"
          data-cy="circle-goToTracker"
          onClick={handleNavigate}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="#3E3E65"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="1.5"
            />
            <path
              d="M10.5 8C10.5 8 13.5 10.946 13.5 12C13.5 13.0541 10.5 16 10.5 16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="tooltip">{redirectText}</div>
      </li>
    </ul>
  );
};

export default IconButtons;
