import { Typography, Box } from "@mui/material";
import { useState } from "react";
import { ProgressBar } from "../progressBar";

import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { OfferResponse } from "..";
import { PropertyProps } from "../Property";

interface CreditScoreProps extends PropertyProps {
  offer?: OfferResponse[];
  loading?: boolean;
  error?: string | null;
}

export const CreditScore = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  offer,
  loading,
  error,
}: CreditScoreProps) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [animation, setAnimation] = useState(false);

  const findCurrentActive = () => {
    const field = creditScore.find(
      (score) => score.value === formData.creditScore,
    );
    return field;
  };

  const handleNext = () => {
    if (!formData.creditScore) {
      setAnimation(true);
      setErrorMessage(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setErrorMessage(false);
      onNext();
    }
  };

  const handleSelect = (value: number) => {
    const field = creditScore[value];
    setCurrentActive(field);
    setFormData({ ...formData, creditScore: field.value });
    setErrorMessage(false);
  };

  const [currentActive, setCurrentActive] = useState<
    | {
        id: number;
        name: string;
        value: number;
      }
    | undefined
  >(findCurrentActive());

  return (
    <div>
      <div className="get-started__wrapper get-started__credit-score">
        <Box>
          <Typography
            variant="h2"
            fontFamily={"NunitoSans"}
            fontSize={{
              xs: "32px",
              sm: "38px",
              md: "38px",
              lg: "40px",
              xl: "40px",
            }}
            textAlign={"center"}
            marginTop={"20px"}
            marginBottom={"20px"}
            fontWeight={900}
          >
            What’s your credit score?
          </Typography>
          <ProgressBar progress={75} />
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "grid",
              },
              gridTemplateColumns: {
                sm: "repeat(3, 1fr)",
              },
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            {creditScore.map((score) => (
              <CHBButton
                className={`animated-button animated-button--score ${
                  currentActive?.id && score.id === currentActive?.id
                    ? "animated-button--active"
                    : ""
                } ${errorMessage ? "animated-button--error" : ""}`}
                classNameContainer={`animated-arrow-button-container--score `}
                key={score.id}
                onClick={() => handleSelect(score.id - 1)}
                style={{
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                }}
              >
                {score.name}
              </CHBButton>
            ))}
          </Box>
          <p
            className="get-started__note"
            style={{
              textAlign: "center",
            }}
          >
            Not sure? You can find this using a credit score site like Credit
            Karma or even your bank.
          </p>
          {error && (
            <p style={{ color: "red", textAlign: "center" }}>{error}</p>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <CHBButton
              onClick={onPrev}
              classNameContainer="button-prev"
              className="animated-button"
            >
              Prev
            </CHBButton>
            <CHBButton
              onClick={handleNext}
              disabled={loading}
              className="animated-arrow-button"
              loading={loading}
            >
              Next
            </CHBButton>
          </Box>
        </Box>
      </div>
      <HBSeparate className="step-separate" type="vertical" />
    </div>
  );
};

const creditScore = [
  {
    id: 1,
    name: "640-659",
    value: 640,
  },
  {
    id: 2,
    name: "660-679",
    value: 660,
  },
  {
    id: 3,
    name: "680-699",
    value: 680,
  },
  {
    id: 4,
    name: "700-719",
    value: 700,
  },
  {
    id: 5,
    name: "720-739",
    value: 720,
  },
  {
    id: 6,
    name: "740-759",
    value: 740,
  },
  {
    id: 7,
    name: "760-779",
    value: 760,
  },
  {
    id: 8,
    name: "780-850",
    value: 780,
  },
  {
    id: 9,
    name: "I don’t know",
    value: 1,
  },
];
