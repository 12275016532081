import { Box } from "@mui/material";
import { Logo } from "./Logo";
import { LinkList } from "./LinkList";

export const Nav = () => {
  return (
    <Box
      className="hb-header"
      display="flex"
      justifyContent="space-between"
      sx={{
        padding: "10px 0",
      }}
      component={"nav"}
    >
      <Logo />
      <LinkList />
    </Box>
  );
};
